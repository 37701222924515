import { MdKeyboardArrowLeft } from "react-icons/md";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import "./style/back.css";
import { useTranslation } from "react-i18next";
const Back = ({ destination }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";

  return (
    <>
      <div
        className="back-btn-wrapper"
        onClick={() => navigate(`/kyc/${destination}`)}
      >
        <div className="back-arrow">
          <IconContext.Provider
            value={{ className: "back-icon", size: "25px" }}
          >
            <MdKeyboardArrowLeft />
          </IconContext.Provider>
        </div>
        <div className="text">{t("Back")} </div>
      </div>
    </>
  );
};

export default Back;
<></>;
