import "./style/verifyEmail.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import utils from "../utils/regex";
import userService from "../services/userService";
const VerifyEmail = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async () => {
    if (!email || !utils.emailRegex.test(email)) {
      setError(`${t("Please enter a valid email")}`);
      return;
    }
    try {
      const response = await userService.verifyEmail(email);
      if (response.message === "success") {
        setError(t("Check your email"));
      } else {
        setError(`${t(`${response.message}`)}`);
      }
    } catch (err) {
      console.log(err, "err in verify email");
    }
  };
  return (
    <>
      <div className="general-container verify-email">
        <h1>{t("Verify Email")}</h1>
        <div
          className="verify-email-wrapper"
          style={{ direction: languageDirection }}
        >
          <input
            type="email"
            name="email"
            placeholder={t("Email")}
            value={email}
            onChange={(e) => {
              setError("");

              setEmail(e.target.value);
            }}
            style={{ marginBottom: error ? "30x" : "50px" }}
          />
          {error && <div className="error">{error}</div>}
          <div className="buttons" style={{ marginTop: error ? "30px" : "0" }}>
            <button onClick={handleSubmit}>{t("Submit")}</button>
            <button onClick={() => navigate("/kyc/login")}>{t("login")}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
