import "./style/contactPopup.css";
import logo from "../images/logo-new.png";
import help1 from "../images/popup/help1.png";
import help2 from "../images/popup/help2.png";
import { useTranslation } from "react-i18next";
import { setContactPopup } from "../redux/mainReducerSlice";
import { useDispatch } from "react-redux";
const ContactPopup = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const handleContactClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      const phoneNumber = "+972528379159";
      window.location.href = `tel:${phoneNumber}`;
    }
  };
  const scanBox = () => {
    const lang = localStorage.getItem("lang");
    if (lang === "he") {
      window.open("https://getyoopi.com/digital-instructions-heb/", "_blank");
    } else {
      window.open("https://getyoopi.com/digital-instructions-eng/", "_blank");
    }
  };
  return (
    <>
      <div className="contact-container">
        <div className="contact-box">
          <header>
            <img src={help1} className="help" alt="" />
            <img src={logo} className="logo" />
            <img src={help2} className="help" alt="" />
          </header>
          <h2 style={{ direction: languageDirection }}>{t("Need help")}</h2>
          <p
            style={{
              direction: languageDirection,
            }}
            onClick={handleContactClick}
          >
            {t("contact us")}
          </p>
          <a href="mailto:Support@getyoopi.com">Support@getyoopi.com</a>
          <button className="scan-box-guide" onClick={scanBox}>
            {t("scan box guide")}
          </button>
          <button
            onClick={() => dispatch(setContactPopup(false))}
            style={{ direction: languageDirection }}
          >
            {t("thanks got it")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ContactPopup;
