import yellow from "../images/boxes/new/Yellow.png";
import green from "../images/boxes/new/Green.png";
import darkBlue from "../images/boxes/new/DarkBlue.png";
import fuchsia from "../images/boxes/new/Fuchsia.png";
import lightBlue from "../images/boxes/new/LightBlue.png";
import lightPink from "../images/boxes/new/LightPink.png";
import skyBlue from "../images/boxes/new/SkyBlue.png";
import pink from "../images/boxes/new/Pink.png";
import lilac from "../images/boxes/new/Lilach.png";
import red from "../images/boxes/new/Red.png";
import aqua from "../images/boxes/new/Aqua.png";
export const boxImages = {
  101: { image: darkBlue, name: "Dark Blue" },
  102: { image: skyBlue, name: "Sky Blue" },
  103: { image: lilac, name: "Purple Lilac" },
  104: { image: lightPink, name: "Light Pink" },
  105: { image: pink, name: "Bubblegum pink" },
  106: { image: lightBlue, name: "Light Blue" },
  107: { image: green, name: "Apple Green" },
  108: { image: yellow, name: "Yellow Sunny" },
  109: { image: red, name: "Strawberry Red" },
  110: { image: fuchsia, name: "Deep Fuchsia" },
  111: { image: aqua, name: "Sea Aqua" },
};
