import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import yellowBox from "../images/boxes/box_yellow.png";
import { boxImages } from "../utils/boxImages";
import { useLocation } from "react-router-dom";

// import kidZone from "../images/kids-zone.png";
import "./style/welcome.css";
import Title from "./title";
import { useNavigate } from "react-router-dom";
import {
  setBoxStatus,
  setBoxAssociation,
  setBoxImage,
  setBoxSize,
  setBoxName,
  setContactPopup,
} from "../redux/mainReducerSlice";
import userService from "../services/userService";
import { useTranslation } from "react-i18next";
import childService from "../services/childService";
const Welcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";

  const boxStatus = useSelector((state) => state.mainReducer.value.boxStatus);
  const boxImage = useSelector((state) => state.mainReducer.value.boxImage);
  const boxSizeRedux = useSelector((state) => state.mainReducer.value.boxSize);
  const boxName = useSelector((state) => state.mainReducer.value.boxName);
  const [disableButtons, setDisableButtons] = useState(false);
  const [error, setError] = useState("");
  // const [boxName, setBoxName] = useState("");
  const location = useLocation();

  const checkUserCurrentStep = (status) => {
    switch (status) {
      case "created":
        navigate("/kyc/diets");
        break;
      case "lifestyle":
        navigate("/kyc/allergies");

        break;
      case "allergies":
        navigate("/kyc/foods");
        break;
      case "favorite_food":
        navigate("/kyc/hobbies");
        break;
      case "hobbies":
        navigate("/kyc/education");
        break;

      default:
        console.log(status, "status of kyc no status match ");
        return false;
    }
  };

  useEffect(() => {
    const calculateChecksum = (f2) => {
      let sumEvenPositions =
        parseInt(f2[1]) +
        parseInt(f2[3]) +
        parseInt(f2[5]) +
        parseInt(f2[7]) +
        parseInt(f2[9]) +
        parseInt(f2[11]);
      let sumOddPositions =
        parseInt(f2[0]) +
        parseInt(f2[2]) +
        parseInt(f2[4]) +
        parseInt(f2[6]) +
        parseInt(f2[8]) +
        parseInt(f2[10]);

      let total = sumEvenPositions * 2 + sumOddPositions;
      let roundedUpTotal = Math.ceil(total / 10) * 10;

      return roundedUpTotal - total;
    };

    const languageCode = navigator.language.slice(0, 2);
    const language = languageCode === "he" ? "he" : "en";
    if (i18n.language !== language) {
      // change language
      i18n.changeLanguage(language);
    }
    localStorage.setItem("lang", language);
    const searchParams = new URLSearchParams(location.search);
    let cid = searchParams.get("cid");
    cid = cid.replace(/[^0-9]/g, ""); // This regex replaces all non-digit characters with an empty string
    const boxProdId = cid.substring(7, 19);
    // get box id from url
    const boxCheckSum = cid.slice(-1);
    const boxColor = cid.substring(0, 3);
    const result = calculateChecksum(boxProdId);
    if (result === parseInt(boxCheckSum)) {
      // set box image
      localStorage.setItem("box", cid);

      // pass box color to redux

      const selectedBox = boxImages[parseInt(boxColor)]; // Get the object associated with the color

      if (selectedBox !== undefined) {
        dispatch(setBoxImage(selectedBox.image));
      } else {
        dispatch(setBoxImage(yellowBox));
      }
      dispatch(setBoxName(selectedBox?.name || "Yellow Sunny"));
      const firstChar = boxColor.charAt(0);
      dispatch(setBoxSize(parseInt(firstChar)));
      const profileId = localStorage.getItem("profileId");
      if (profileId) {
        // if profile id exist in local storage
        // check if box exist and its association
        const checkBoxAssociation = async (boxIdentifier, profileId) => {
          try {
            const response = await userService.checkBoxAssociation(
              boxIdentifier,
              profileId
            );
            if (response.message === "missing data") {
              setError(t("Missing Box Identifier"));
              dispatch(setContactPopup(true));
              return;
            } else if (response.message === "box assigned to another account") {
              setError(t("This YOOPI box is assigned to another account!"));

              setDisableButtons(true);
              dispatch(setBoxStatus(true));
              return;
            } else if (
              // in both cases we transfer user to website with the correct profile id
              response.message === "box assigned to another family member" ||
              response.message === "box assigned to current profile"
            ) {
              dispatch(setBoxAssociation(response.message));

              const token = localStorage.getItem("token");
              // if box assigned to one of household members we return profile id that match box and transfer user to website if token exist in local storage
              if (token) {
                await userService.logActivity(response.profileId);

                const finishedKyc = await childService.checkKycStatus(
                  response.profileId
                );

                if (finishedKyc.message === "success") {
                  if (finishedKyc.kycStatus === "kyc-finished") {
                    window.location.href = `https://my.getyoopi.com/${encodeURIComponent(
                      token
                    )}/${encodeURIComponent(
                      response.profileId
                    )}/${encodeURIComponent(boxColor)}`;
                    return;
                  } else {
                    checkUserCurrentStep(finishedKyc.kycStatus);
                  }
                }
              } else {
                // if token does not exist in local storage
                navigate("/kyc/login");
                return;
              }
            }
            // else box is not registered we do nothing
          } catch (err) {
            console.log(err, "err in checkBoxAssociation");
          }
        };
        checkBoxAssociation(cid, profileId);
      } else {
        // check if box is registered no profile id in local storage

        const checkIfBoxExist = async (boxIdentifier) => {
          try {
            const boxExist = await userService.checkIfBoxExist(boxIdentifier);
            if (boxExist.message === "box exist in db") {
              dispatch(setBoxAssociation(boxExist.message));
              dispatch(setBoxStatus(true));
              navigate("/kyc/login");
              return;
            }
            // else box not registered do nothing let user continue
            dispatch(setBoxAssociation("box not registered"));
          } catch (err) {
            console.log(err, "err in checkIfBoxExist");
          }
        };
        checkIfBoxExist(cid);
      }
    } else {
      setError(t("Invalid Box Identifier"));
      setDisableButtons(true);
      dispatch(setContactPopup(true));

      return;
    }
  }, []);

  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const handleParent = async () => {
    if (boxStatus) return;
    navigate("/kyc/check-account");
  };

  return (
    <>
      <div className="welcome-container">
        <Title
          para={
            desktopView ? `${t("OMG_Its_desktop")}` : `${t("OMG_Its_mobile")}`
          }
          mainTitle={`${t(`${boxName}`)}`}
          boxSize={boxSizeRedux}
        />

        <div className="main-box">
          {/* if box scan absent  correct color display yellow box placeholder */}
          <img src={boxImage} alt="" />
        </div>
        {error && (
          <>
            <div className="error" style={{ marginTop: "50px" }}>
              {error}
            </div>
          </>
        )}
        <div
          className="need-help"
          style={{
            direction: languageDirection,
            marginTop: error ? "20px" : "50px",
          }}
          onClick={() => dispatch(setContactPopup(true))}
        >
          {t("Need help")}
        </div>
        <div className="buttons">
          <div className="parent">
            <button
              className="orange-btn"
              style={{ direction: languageDirection }}
              onClick={disableButtons ? null : handleParent}
            >
              {t("Im_parent")}
            </button>
          </div>
          <div className="kids-zone">
            <button
              className="color-btn"
              onClick={() => navigate("/kids-zone")}
            >
              {t("Kids Zone")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
