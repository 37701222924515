import Title from "./title";
import defaultAvatar from "../images/avatar/new/web/001-lemon-new.png";
import { IconContext } from "react-icons/lib/cjs/iconContext";
import { AiOutlineEdit } from "react-icons/ai";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setChildProfileRedux } from "../redux/mainReducerSlice";
import "./style/createNewProfile.css";
import childService from "../services/childService";
import { useTranslation } from "react-i18next";
const CreateNewProfile = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [firstName, setFirstName] = useState("");
  const [error, setError] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [chosenAvatar, setChosenAvatar] = useState(null);
  const [
    isSelectedAvatarCapturedOnCamera,
    setIsSelectedAvatarCapturedOnCamera,
  ] = useState(false);
  const navigate = useNavigate();

  const avatarDetailsRedux = useSelector(
    (state) => state.mainReducer.value.selectedAvatar
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }
  }, []);
  useEffect(() => {
    const checkAvatar = (avatarName) => {
      if (avatarName === "Captured") {
        setIsSelectedAvatarCapturedOnCamera(true);
      } else {
        setIsSelectedAvatarCapturedOnCamera(false);
      }
    };

    if (avatarDetailsRedux.avatarName) {
      setChosenAvatar(avatarDetailsRedux.avatarSrc);
      checkAvatar(avatarDetailsRedux.avatarName);
    } else {
      setChosenAvatar(defaultAvatar);
    }
  }, [avatarDetailsRedux]);

  const handleNumberChange = (input, date) => {
    // You can add additional validation if needed
    if (date === "year") {
      setYear(input);
    } else {
      setMonth(input);
    }
  };

  const handleInput = (e, length) => {
    if (e.target.value.length > length) {
      e.target.value = e.target.value.slice(0, length);

      if (length === 4) {
        setYear(e.target.value);
      } else {
        setMonth(e.target.value);
      }
    }
  };

  const handleNewProfile = async (e) => {
    e.preventDefault();
    setError("");
    if (!firstName || !year || !month) {
      setError(t("Please fill all the fields"));
      return;
    }

    if (firstName === "") {
      setError(t("First Name Is Required"));
      return;
    }
    if (firstName.length < 2) {
      setError(t("First Name Must Be At Least 2 Characters"));
      return;
    }

    const yearTest = parseFloat(year);

    if (yearTest < 1923 || yearTest > 2023) {
      setError(t("Please Enter Valid Year 1923 - 2023"));
      return;
    }

    const monthTest = parseFloat(month);
    if (monthTest < 1 || monthTest > 12) {
      setError(t("Please Enter Valid Month"));
      return;
    }
    const boxIdentifier = localStorage.getItem("box");
    try {
      setDisabled(true);
      const createProfile = await childService.createChildProfile({
        firstName,
        year,
        month,
        avatar: chosenAvatar,
        avatarName: avatarDetailsRedux.avatarName
          ? avatarDetailsRedux.avatarName
          : "lemon",
        boxIdentifier,
      });
      console.log(createProfile, "createProfile");
      if (
        createProfile.message === "success" ||
        createProfile.message === "box registered"
      ) {
        // if message is box registered user pressed back button in browser
        // so we dont create another profile

        localStorage.setItem("profileId", createProfile.childId);

        dispatch(
          setChildProfileRedux({
            firstName,
            yearOfBirth: year,
            monthOfBirth: month,
            avatar: chosenAvatar,
          })
        );
        if (createProfile.message === "success") {
          toast.success(t("Profile Created Successfully"));
        }
        navigate("/kyc/diets");
      } else if (
        createProfile.message ===
        "Duplicate entry error: This Box already exists in db"
      ) {
        toast.error(t("This Box already exists in db"));
      } else {
        toast.error(t("Error in creating profile"));
        console.log(createProfile, "createProfile error");
        setDisabled(false);
      }
    } catch (err) {
      console.log(err, "error in create new profile");
    }
  };

  return (
    <>
      <Title para={null} mainTitle={t("Tell us about your little one")} />
      <div className="general-container create-profile">
        <div className="avatar-image">
          <img
            src={chosenAvatar}
            className={
              isSelectedAvatarCapturedOnCamera
                ? "camera-avatar"
                : "yoopi-avatar"
            }
            alt=""
          />
        </div>
        <div className="change-avatar-btn">
          <div className="edit">
            <IconContext.Provider value={{ className: "edit-icon" }}>
              <AiOutlineEdit />
            </IconContext.Provider>
          </div>
          <div
            className="change"
            onClick={() => navigate("/kyc/change-avatar")}
          >
            {t("Change avatar")}
          </div>
        </div>

        <div className="create-profile-form">
          <form>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                style={{
                  padding:
                    languageDirection === "rtl" ? "0 20px 0 0 " : "0 0 0 20px",
                }}
                type="text"
                name="first_name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t("First Name")}
              />
            </div>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                style={{
                  padding:
                    languageDirection === "rtl" ? "0 20px 0 0 " : "0 0 0 20px",
                }}
                type="number"
                maxLength="4"
                length="4"
                name="birthday-year"
                placeholder={t("Birthday Year")}
                onChange={(e) => handleNumberChange(e.target.value, "year")}
                onInput={(e) => handleInput(e, 4)}
                value={year}
              />
            </div>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                style={{
                  padding:
                    languageDirection === "rtl" ? "0 20px 0 0 " : "0 0 0 20px",
                }}
                type="text"
                name="birthday_month"
                placeholder={t("Birthday Month")}
                onChange={(e) => handleNumberChange(e.target.value, "month")}
                onInput={(e) => handleInput(e, 2)}
                value={month}
              />
            </div>
            {error && <div className="error">{error}</div>}
            <button
              style={{ direction: languageDirection }}
              className="button-wrapper"
              onClick={disabled ? null : (e) => handleNewProfile(e)}
            >
              <div className="create">{t("Next")}</div>
              <div className="arrow">
                <IconContext.Provider value={{ className: "arrow-icon" }}>
                  {languageDirection === "rtl" ? (
                    <MdKeyboardArrowLeft
                      className="arrow-icon"
                      style={{ fontSize: "25px" }}
                    />
                  ) : (
                    <MdKeyboardArrowRight
                      className="arrow-icon"
                      style={{ fontSize: "25px" }}
                    />
                  )}
                </IconContext.Provider>
              </div>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateNewProfile;
