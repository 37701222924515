const envType = process.env.NODE_ENV;

console.log(envType, "envType");

let imageBaseUrl;

if (envType === "production") {
  imageBaseUrl = "https://api.getyoopi.com/";
} else {
  imageBaseUrl = "http://localhost:8080/";
}

export default imageBaseUrl;
