import "./style/footer.css";
import blog from "../images/desktop/footer/blog.png";
import stars from "../images/desktop/footer/stars.png";
import video from "../images/desktop/footer/video.png";
import { setCleanBoxPopup } from "../redux/mainReducerSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaUsers } from "react-icons/fa6";
import { FaHeadphonesAlt } from "react-icons/fa";
import { IoIosHelpBuoy } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { IconContext } from "react-icons/lib";
import { useEffect } from "react";
import { setContactPopup } from "../redux/mainReducerSlice";
const Footer = () => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  useEffect(() => {
    const languageCode = localStorage.getItem("lang");

    i18n.changeLanguage(languageCode);
  }, []);
  const dispatch = useDispatch();
  const handleAboutUs = () => {
    if (i18n.language === "he") {
      window.open("https://getyoopi.com/about/", "_blank");
    } else {
      window.open("https://getyoopi.com/about-us-en/", "_blank");
    }
  };
  const handlePrivacy = () => {
    if (i18n.language === "he") {
      window.open("https://getyoopi.com/privacy-policy-heb/", "_blank");
    } else {
      window.open("https://getyoopi.com/privacy-policy-eng/", "_blank");
    }
  };
  return (
    <>
      {desktopView ? (
        <>
          {" "}
          <div
            className="footer"
            style={{
              flexDirection:
                languageDirection === "rtl" ? "row-reverse" : "row",
            }}
          >
            <div
              className="main"
              style={{
                justifyContent:
                  languageDirection === "rtl" ? "flex-end" : "flex-start",
              }}
            >
              {/* <div className="videos">
                <img src={video} alt="" />
                <p>{t("Videos")}</p>
              </div> */}
              <div
                className="clean"
                onClick={() => dispatch(setCleanBoxPopup({ popupState: true }))}
              >
                <img src={stars} alt="" />
                <p>{t("How to clean your box")}</p>
              </div>
              <div
                className="blog"
                onClick={() =>
                  window.open("https://getyoopi.com/blog-post/", "_blank")
                }
              >
                <img src={blog} alt="" />
                <p>{t("To our blog")}</p>
              </div>
              <div className="privacy" onClick={handlePrivacy}>
                <IconContext.Provider
                  value={{ size: "20px", color: "#B89CD1" }}
                >
                  <MdOutlinePrivacyTip className="footer-icon" />
                </IconContext.Provider>
                <p>{t("Privacy Policy")}</p>
              </div>
              <div
                className="contact-us"
                onClick={() =>
                  window.open("https://getyoopi.com/contact-us/", "_blank")
                }
              >
                <IconContext.Provider
                  value={{ size: "20px", color: "#B89CD1" }}
                >
                  <FaHeadphonesAlt className="footer-icon" />
                </IconContext.Provider>
                <p>{t("Contact us")}</p>
              </div>
              <div className="about-us" onClick={handleAboutUs}>
                <IconContext.Provider
                  value={{ size: "20px", color: "#B89CD1" }}
                >
                  <FaUsers className="footer-icon" />
                </IconContext.Provider>
                <p>{t("About us")}</p>
              </div>
              <div
                className="help"
                onClick={() => dispatch(setContactPopup(true))}
              >
                <IconContext.Provider
                  value={{ size: "20px", color: "#B89CD1" }}
                >
                  <IoIosHelpBuoy className="footer-icon" />
                </IconContext.Provider>
                <p>{t("Help")}</p>
              </div>
            </div>
            <div
              className="credit"
              style={{
                justifyContent:
                  languageDirection === "rtl" ? "flex-start" : "flex-end",
              }}
            >
{/*               <p>{t("Credit")}</p> */}
            </div>
          </div>
        </>
      ) : (
        // mobile view
        <>
          <div
            className="footer mobile"
            style={{
              flexDirection:
                languageDirection === "rtl" ? "row-reverse" : "row",
            }}
          >
            <div className="main mobile">
              <div
                className="blog"
                onClick={() =>
                  window.open("https://getyoopi.com/blog-post/", "_blank")
                }
              >
                <img src={blog} alt="" />
              </div>
              <div className="privacy" onClick={handlePrivacy}>
                <IconContext.Provider
                  value={{ size: "35px", color: "#B89CD1" }}
                >
                  <MdOutlinePrivacyTip className="footer-icon" />
                </IconContext.Provider>
              </div>
              <div
                className="contact-us"
                onClick={() =>
                  window.open("https://getyoopi.com/contact-us/", "_blank")
                }
              >
                <IconContext.Provider
                  value={{ size: "35px", color: "#B89CD1" }}
                >
                  <FaHeadphonesAlt className="footer-icon" />
                </IconContext.Provider>
              </div>

              <div
                className="help"
                onClick={() => dispatch(setContactPopup(true))}
              >
                <IconContext.Provider
                  value={{ size: "35px", color: "#B89CD1" }}
                >
                  <IoIosHelpBuoy className="footer-icon" />
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
