import "./App.css";
import Footer from "./Components/footer";
import Welcome from "./Components/welcome";
import { useMediaQuery } from "react-responsive";
import CheckAccount from "./Components/checkAccount";
import Register from "./Components/register";
import Login from "./Components/login";
import Header from "./Components/header";
import Verification from "./Components/verification";
import PickUser from "./Components/pickUser";
import ChangeAvatar from "./Components/changeAvatar";
import Foods from "./Components/foods";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import CreateNewProfile from "./Components/createNewProfile";
import Diets from "./Components/diets";
import ProtectedRoutes from "./utils/protectedRoute";
import Hubbies from "./Components/hubbies";
import Allergies from "./Components/allergies";
import Disclaimer from "./Components/disclaimer";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import Education from "./Components/education";
import BoxPopup from "./Components/boxPopup";
import KidsZone from "./Components/kidsZone";
import ContactPopup from "./Components/contactPopup";
import VerifyEmail from "./Components/VerifyEmail";
import ResetPassword from "./Components/resetPassword";
function App() {
  const desktopView = useMediaQuery({ query: "(min-width: 750px)" });
  const disclaimerPopupIsOpen = useSelector(
    (state) => state.mainReducer.value.disclaimerPopupIsOpen
  );
  const cleanBoxPopupIsOpen = useSelector(
    (state) => state.mainReducer.value.cleanBoxPopupIsOpen
  );
  const contactPopupIsOpen = useSelector(
    (state) => state.mainReducer.value.contactPopupIsOpen
  );
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/kyc/welcome/box" element={<Welcome />} exact />
        <Route path="/kyc/check-account" element={<CheckAccount />} exact />
        <Route path="/kyc/register" element={<Register />} exact />
        <Route path="/kyc/login" element={<Login />} exact />
        <Route path="/kyc/verifyEmail" element={<VerifyEmail />} exact />
        <Route path="/kyc/verification/:email" element={<Verification />} />
        <Route
          path="/kyc/reset-password/:code/:email"
          element={<ResetPassword />}
          exact
        />
        <Route element={<ProtectedRoutes />}>
          <Route path="/kyc/pick-user" element={<PickUser />} exact />
          <Route path="/kyc/change-avatar" element={<ChangeAvatar />} exact />
          <Route
            path="/kyc/create-new-profile"
            element={<CreateNewProfile />}
            exact
          />
          <Route path="/kyc/foods" element={<Foods />} exact />
          <Route path="/kyc/diets" element={<Diets />} exact />
          <Route path="kyc/hubbies" element={<Hubbies />} exact />
          <Route path="/kyc/allergies" element={<Allergies />} exact />
          <Route path="/kyc/education" element={<Education />} exact />
        </Route>
        <Route path="/kids-zone" element={<KidsZone />} exact />

        <Route path="/*" element={<Navigate to="/kyc/welcome" />} />
      </>
    )
  );

  return (
    <>
      <div
        className={
          disclaimerPopupIsOpen || cleanBoxPopupIsOpen || contactPopupIsOpen
            ? "popup-open"
            : "App"
        }
      >
        <div className="main-content">
          <Header />

          <RouterProvider router={routes} />
        </div>

        <Footer />
      </div>
      {disclaimerPopupIsOpen && <Disclaimer />}
      {cleanBoxPopupIsOpen && <BoxPopup />}
      {contactPopupIsOpen && <ContactPopup />}
    </>
  );
}

export default App;
