import { useMediaQuery } from "react-responsive";
import logo from "../images/logo-new.png";
import "./style/header.css";
import { GrLanguage } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import { useEffect, useState } from "react";

import Select from "react-select";
const Header = () => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const mobileViewForSelect = useMediaQuery({ query: "(max-width:350px)" });
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const languageOptions = [
    { value: "en", label: t("English") },
    { value: "he", label: t("hebrew") },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      width: mobileViewForSelect ? "8vw" : "13vw",
      maxWidth: "150px",
      minWidth: mobileViewForSelect ? "70px" : "120px",
      borderRadius: "4px",
      border: "2px solid #3C4964", // Replace --color-name with your color variable
      marginBottom: "10px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      direction: languageDirection,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    menu: (provided) => ({
      ...provided,
      direction: languageDirection,
    }),
  };
  useEffect(() => {
    const languageCode = localStorage.getItem("lang");
    if (languageCode) {
      const selectedOption = languageOptions.find(
        (option) => option.value === languageCode
      );
      if (selectedOption) {
        setSelectedLanguage(selectedOption);
      }
      i18n.changeLanguage(languageCode);
    }
  }, []);
  const handleLanguageChange = (selectedOption) => {
    if (selectedOption.value === i18n.language) return;

    localStorage.setItem("lang", selectedOption.value);
    i18n.changeLanguage(selectedOption.value);
    setSelectedLanguage(selectedOption);
  };
  return (
    <>
      <div className="header-container">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="language">
          {/* <IconContext.Provider value={{ size: "20px" }}>
            <GrLanguage />
          </IconContext.Provider> */}
          <Select
            styles={customStyles}
            options={[
              { value: "en", label: t("English"), icon: <GrLanguage /> },
              { value: "he", label: t("hebrew"), icon: <GrLanguage /> },
            ]}
            // value={selectedLanguage}
            placeholder={<GrLanguage />}
            onChange={handleLanguageChange}
          />
        </div>
      </div>
      {desktopView && (
        <>
          <div className="desktop-divider"></div>
        </>
      )}
    </>
  );
};

export default Header;
