import box from "../images/yellow-box-new.png";
import { useEffect, useState } from "react";
import "./style/pickUser.css";
import { IconContext } from "react-icons/lib/cjs/iconContext";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import childService from "../services/childService";
import { useSelector } from "react-redux/es/hooks/useSelector";
import lemon from "../images/avatar/new/web/001-lemon-new.png";
import crab from "../images/avatar/new/web/crab.png";
import summer from "../images/avatar/new/web/007-summer-1.png";
import whale from "../images/avatar/new/web/008-whale-1.png";
import lemonade from "../images/avatar/new/web/009-lemonade-1.png";
import watermelon from "../images/avatar/new/web/012-watermelon-1.png";
import orange from "../images/avatar/new/web/014-orange-1.png";
import pineapple from "../images/avatar/new/web/015-pineapple-1.png";
import strawberry from "../images/avatar/new/web/016-strawberry-1.png";
import { setBoxImage } from "../redux/mainReducerSlice";
import { boxImages } from "../utils/boxImages";
import yellowBox from "../images/boxes/box_yellow.png";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const PickUser = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const boxImage = useSelector((state) => state.mainReducer.value.boxImage);
  const avatars = [
    { id: 1, image: lemon, name: "lemon" },
    { id: 2, image: crab, name: "crab" },
    { id: 3, image: summer, name: "summer" },
    { id: 4, image: whale, name: "whale" },
    { id: 5, image: lemonade, name: "lemonade" },
    { id: 6, image: watermelon, name: "watermelon" },
    { id: 7, image: orange, name: "orange" },
    { id: 8, image: pineapple, name: "pineapple" },
    { id: 9, image: strawberry, name: "strawberry" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const checkForExistingUsers = async () => {
      const children = await childService.getAllChildProfiles();
      if (children.message === "success") {
        const updatedUsers = children.data.map((user) => {
          const matchingAvatar = avatars.find(
            (avatar) => avatar.name.toLowerCase() === user.avatar.toLowerCase()
          );
          if (matchingAvatar) {
            return { ...user, avatar: matchingAvatar.image };
          } else {
            return user;
          }
        });
        setUsers(updatedUsers);
      } else {
        setUsers([]);
        // first profile
      }
    };

    checkForExistingUsers();
    const boxIdentifier = localStorage.getItem("box");
    if (!boxIdentifier) {
      navigate("/kyc/welcome");
      return;
    } else {
      const boxColor = boxIdentifier.substring(0, 3);
      dispatch(setBoxImage(boxImages[parseInt(boxColor)]));

      // check language
      const language = localStorage.getItem("lang");
      if (language) {
        if (language !== i18n.language) {
          i18n.changeLanguage(language);
        }
      }
    }
  }, []);

  const addBoxToExistingAccount = async (childId) => {
    //in the future we add box to account and transfer the user to website
    const boxIdentifier = localStorage.getItem("box");
    if (!boxIdentifier) {
      console.log("no box identifier");
      navigate("/kyc/welcome");
      return;
    }
    try {
      const addBox = await childService.addBoxToExistingAccount(
        childId,
        boxIdentifier
      );
      if (addBox.message === "success") {
        localStorage.setItem("profileId", childId);
        //  check all kyc steps
        switch (addBox.step_in_kyc) {
          // check step in kyc
          case "created":
            navigate("/kyc/diets");
            break;
          case "lifestyle":
            navigate("/kyc/allergies");
            break;
          case "allergies":
            navigate("/kyc/foods");
            break;
          case "favorite_food":
            navigate("/kyc/hubbies");
            break;
          case "hubbies":
            navigate("/kyc/education");
            break;
          case "kyc-finished":
            // last step in kyc
            const boxColor = boxIdentifier.substring(0, 3);
            window.location.href = `http://my.getyoopi.com/${encodeURIComponent(
              localStorage.getItem("token")
            )}/${encodeURIComponent(childId)}/${encodeURIComponent(boxColor)}`;
          default:
            console.log(
              addBox.step_in_kyc,
              "addBox.step_in_kyc default switch case"
            );
            break;
        }
      } else {
        console.log(addBox, "addBox error");
      }
    } catch (err) {
      console.log(err, "err in addBoxToExistingAccount");
    }
  };
  const handleNewProfile = () => {
    setError("");

    if (users.length > 8) {
      setError(`${t("it is not possible to create more then 9 profiles")}`);
      return;
    }
    navigate("/kyc/create-new-profile");
  };
  return (
    <>
      <div className="general-container pick-user">
        <div className="space"></div>
        <div className="main-image">
          <img src={boxImage?.image ? boxImage?.image : yellowBox} alt="" />
        </div>

        <div className="no-users" style={{ direction: languageDirection }}>
          {t("This box belongs to...")}
        </div>
        {users.length > 0 && (
          <div className="add-to-profile">{t("Add to existing profile")}</div>
        )}

        <div className="existing-users-wrapper">
          {users.length > 0 && (
            <>
              {users.map((user) => {
                return (
                  <div
                    className="user"
                    key={user.id}
                    onClick={() => addBoxToExistingAccount(user.id)}
                  >
                    <div className="user-img">
                      <img
                        src={user?.image ? user.image : user.avatar}
                        alt=""
                      />
                    </div>
                    <div className="user-name">{user.first_name}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        {error && <div className="error">{error}</div>}
        <div
          onClick={handleNewProfile}
          className="button-wrapper"
          style={{ direction: languageDirection }}
        >
          <button>{`${t("Create new")}`}</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickUser;
