import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setContactPopup } from "../redux/mainReducerSlice";
import { useDispatch } from "react-redux";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { IconContext } from "react-icons";
import userService from "../services/userService";
import "./style/resetPassword.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const navigate = useNavigate();
  const { code, email } = useParams();
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableFields, setDisableFields] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!code || !email) {
      setError(t("Contact support for help"));
      setTimeout(() => {
        dispatch(setContactPopup({ popupState: true }));
      }, 2000);
    } else {
      // check code and email
      const checkVerificationDetails = async () => {
        const response = await userService.checkVerificationDetails(
          email,
          code
        );
        if (response.message === "success") {
          setDisableFields(false);
        } else {
          setError(t(`${response.message}`));
          return;
        }
      };
      checkVerificationDetails();
    }
  }, []);
  const handleResetPassword = async () => {
    if (!password) {
      setError(t("Please enter a password"));
      return;
    }
    if (password.length < 5) {
      setError(t("Password must be at least 5 characters"));
      return;
    }
    if (password !== confirmPassword) {
      setError(t("Passwords do not match"));
      return;
    }
    try {
      const response = await userService.resetPassword(email, password, code);
      if (response.message === "success") {
        toast.success(t("Password reset successfully"));
        navigate("/kyc/login");
      } else {
        setError(t(`${response.message}`));
      }
    } catch (err) {
      console.log(err, "error in reset password");
    }
  };
  return (
    <>
      <div className="general-container reset-password-container">
        <h1>{t("Reset Password")}</h1>

        <div
          className="reset-password-wrapper"
          style={{ direction: languageDirection }}
        >
          <label htmlFor="password">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder={t("Password")}
              value={password}
              onChange={(e) => {
                setError("");
                setPassword(e.target.value);
              }}
              style={{ marginBottom: error ? "30px" : "50px" }}
              disabled={disableFields}
            />
            <span onClick={() => setShowPassword(!showPassword)}>
              <IconContext.Provider
                value={{ className: "eye-icon", size: "1.5em" }}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </IconContext.Provider>
            </span>
          </label>
          <label htmlFor="confirmPassword">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              placeholder={t("Confirm Password")}
              value={confirmPassword}
              onChange={(e) => {
                setError("");
                setConfirmPassword(e.target.value);
              }}
              style={{ marginBottom: error ? "30px" : "50px" }}
              disabled={disableFields}
            />
            <span onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              <IconContext.Provider
                value={{ className: "eye-icon", size: "1.5em" }}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </IconContext.Provider>
            </span>
          </label>
        </div>
        {error && (
          <div
            className="error"
            style={{ marginTop: "20px", direction: languageDirection }}
          >
            {error}
          </div>
        )}
        <div
          className="button"
          style={{ marginTop: error ? "30px" : "0" }}
          onClick={handleResetPassword}
        >
          {t("Submit")}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
