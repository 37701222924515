import "./style/disclaimer.css";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { setDisclaimerPopup } from "../redux/mainReducerSlice";
import { useDispatch } from "react-redux";
import star from "../images/disclaimer.png";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
const Disclaimer = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  //   const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");

  const handleDisclaimer = () => {
    if (!checked) {
      setError(`${t("Please check the box to proceed")}`);
      return;
    }

    dispatch(setDisclaimerPopup({ popupState: false }));
  };
  const handleReadMore = () => {
    if (i18n.language === "he") {
      window.open("https://getyoopi.com/allergy_disclaimer_he/", "_blank");
    } else {
      window.open("https://getyoopi.com/allergy_disclaimer_en/", "_blank");
    }
  };
  return (
    <>
      <div
        className="disclaimer-container"
        style={{ direction: languageDirection }}
      >
        <div className="star">
          <img src={star} alt="" />
        </div>
        <div className="disclaimer">
          <h2>{t("Disclaimer")} </h2>
          <p>
            {t("children_safety")}
            <span>
              <br />
              <span onClick={() => handleReadMore()}>{t("Read More")}</span>
            </span>
          </p>
        </div>

        <div className="form-wrapper">
          <input
            type="checkbox"
            name="check"
            value={checked}
            id="check"
            onChange={(e) => {
              setError("");
              setChecked(e.target.checked);
            }}
          />
          <label htmlFor="check">{t("I have read and agree")}</label>
        </div>
        {error && <div className="error">{error}</div>}
        <div
          className="button-wrapper"
          style={{ direction: languageDirection }}
          onClick={handleDisclaimer}
        >
          <button>{t("Got it")}</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
