import "./style/title.css";
import { useTranslation } from "react-i18next";
const Title = ({ para, mainTitle, boxSize = null }) => {
  const { i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";

  return (
    <>
      <div className="title" style={{ direction: languageDirection }}>
        {para && <p>{para}</p>}

        <h1
          style={{
            direction: languageDirection,
            marginTop: para ? "" : "30px",
            width: "100%",
            marginBottom: "30px",
          }}
        >
          {mainTitle}
        </h1>
        {boxSize !== null && (
          <p className="box-size">
            {boxSize === 2 ? "Mini YOOPI To-Go" : "YOOPI To-Go"}
          </p>
        )}
      </div>
    </>
  );
};

export default Title;
