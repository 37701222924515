import "./style/register-login.css";
import Title from "./title";
import { IconContext } from "react-icons/lib/cjs/iconContext";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import utils from "../utils/regex";
import userService from "../services/userService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const Register = () => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const { t, i18n } = useTranslation();

  const [error, setError] = useState("");
  const [buttonText, setButtonText] = useState("confirm");
  const [disableButtons, setDisableButtons] = useState(false);
  const navigate = useNavigate();

  const [registerForm, setRegisterForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    approvedMarketing: true,
  });
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }
  }, []);
  const handleFormChange = (e) => {
    // if true than already registered
    if (error === "Check your email for verification link") return;

    setError("");
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    if (buttonText === "Login") {
      navigate("/kyc/login");
      return;
    }

    // if true than already registered dont send another request
    if (error === "Check your email for verification link") return;

    if (!registerForm.firstName || registerForm.firstName.length < 2) {
      setError(`${t("First name is required at least 2 characters")}`);
      return;
    }
    if (!registerForm.lastName || registerForm.lastName.length < 2) {
      setError(`${t("Last name is required at least 2 characters")}`);
      return;
    }
    if (!registerForm.email || !utils.emailRegex.test(registerForm.email)) {
      setError(`${t("Please enter a valid email")}`);
      return;
    }
    if (!registerForm.password) {
      setError(`${t("Please enter a password")}`);
      return;
    }
    if (registerForm.password.length < 5) {
      setError(`${t("Password must be at least 5 characters")}`);

      return;
    }

    try {
      // add language to registerForm
      // const languageCode = navigator.language.slice(0, 2);
      setDisableButtons(true);
      const language = localStorage.getItem("lang") || i18n.language;

      registerForm.language = language;
      const registerUser = await userService.register(registerForm);

      console.log(registerForm, "registerForm");
      console.log(registerUser, "registerUser");
      if (registerUser.message === "success") {
        navigate(`/kyc/verification/${registerForm.email}`);

        return;
      } else if (registerUser.message === "email already exists please login") {
        setButtonText("Login");
        setDisableButtons(false);
        setError(`${t(registerUser.message)}`);
        console.log(registerUser, "registerUser error");
      }
    } catch (err) {
      console.log(err, "err in registration");
    }
  };
  const handleTerms = (type) => {
    const language = localStorage.getItem("lang");
    let link;
    if (type === "terms") {
      if (language === "he") {
        link = "https://getyoopi.com/terms-and-conditions-heb";
      } else {
        link = "https://getyoopi.com/terms-and-conditions-eng";
      }
    } else {
      if (language === "he") {
        link = "https://getyoopi.com/privacy-policy-heb";
      } else {
        link = "https://getyoopi.com/privacy-policy-eng";
      }
    }

    const a = document.createElement("a");
    a.href = link;
    a.target = "_blank";
    a.click();

    a.remove();
  };
  return (
    <>
      <div className="register-container">
        <Title
          para={t("Ready_set_go")}
          mainTitle={t("Tell us a bit about yourself")}
        />

        <div className="register-form-wrapper">
          <form>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                type="text"
                value={registerForm.firstName}
                name="firstName"
                placeholder={t("First name:")}
                onChange={handleFormChange}
              />
            </div>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                type="text"
                value={registerForm.lastName}
                name="lastName"
                placeholder={t("Last name:")}
                onChange={handleFormChange}
              />
            </div>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                type="email"
                name="email"
                value={registerForm.email}
                placeholder={t("Email")}
                onChange={handleFormChange}
                className="email-input"
              />
            </div>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                type="password"
                name="password"
                value={registerForm.password}
                placeholder={t("Password")}
                onChange={handleFormChange}
              />
            </div>
            <div
              className="approve-marketing"
              style={{
                direction: languageDirection,
                margin:
                  languageDirection === "rtl" ? "0 0 20x 0" : "0 0 0 20px",
              }}
            >
              <input
                type="checkbox"
                name=""
                id=""
                checked={registerForm.approvedMarketing}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    approvedMarketing: e.target.checked,
                  })
                }
                style={{
                  margin:
                    languageDirection === "rtl" ? "0 0 0 10px" : "0 10px 0 0",
                }}
              />
              <span className="terms" onClick={() => handleTerms("terms")}>
                {t("Terms of Use")}
              </span>
              <span
                className="privacy"
                style={{
                  margin:
                    languageDirection === "rtl" ? "0 5px 0 0" : "0 0 0 5px",
                }}
                onClick={() => handleTerms("privacy")}
              >
                {t("Privacy Policy")}
              </span>
            </div>
            {error && <p className="error">{error}</p>}
            <button
              className="form-submission"
              onClick={(e) => handleFormSubmission(e)}
              style={{
                marginTop: "50px",
                direction: languageDirection,
              }}
              disabled={disableButtons}
            >
              <input type="button" value={t(`${buttonText}`)} />
              <div className="arrow">
                <IconContext.Provider value={{ className: "arrow-icon" }}>
                  {languageDirection === "rtl" ? (
                    <MdKeyboardArrowLeft
                      className="arrow-icon"
                      style={{ fontSize: "30px" }}
                    />
                  ) : (
                    <MdKeyboardArrowRight
                      className="arrow-icon"
                      style={{ fontSize: "30px" }}
                    />
                  )}
                </IconContext.Provider>
              </div>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
