import { createSlice } from "@reduxjs/toolkit";

export const mainReducerSlice = createSlice({
  name: "mainReducer",
  initialState: {
    value: {
      boxStatus: false,
      boxAssociation: "",
      boxImage: 0,
      boxSize: "",
      boxName: "",
      selectedAvatar: {
        avatarName: "",
        avatarSrc: "",
      },
      childProfile: {
        firstName: "",
        yearOfBirth: "",
        monthOfBirth: "",
        avatar: "",
      },
      selectedDiet: "",
      disclaimerPopupIsOpen: false,
      cleanBoxPopupIsOpen: false,
      contactPopupIsOpen: false,
    },
  },
  reducers: {
    setSelectedAvatarRedux: (state, action) => {
      const { avatarName, avatarSrc } = action.payload;

      state.value.selectedAvatar = {
        avatarName,
        avatarSrc,
      };
    },
    setChildProfileRedux: (state, action) => {
      const { firstName, yearOfBirth, monthOfBirth, avatar } = action.payload;

      state.value.childProfile = {
        firstName,
        yearOfBirth,
        monthOfBirth,
        avatar,
      };
    },
    setDisclaimerPopup: (state, action) => {
      const { popupState } = action.payload;
      state.value.disclaimerPopupIsOpen = popupState;
    },
    setCleanBoxPopup: (state, action) => {
      const { popupState } = action.payload;
      state.value.cleanBoxPopupIsOpen = popupState;
    },
    setBoxStatus: (state, action) => {
      state.value.boxStatus = action.payload;
    },
    setBoxAssociation: (state, action) => {
      state.value.boxAssociation = action.payload;
    },
    setBoxImage: (state, action) => {
      state.value.boxImage = action.payload;
    },
    setSelectedDiet: (state, action) => {
      state.value.selectedDiet = action.payload;
    },
    setBoxSize: (state, action) => {
      state.value.boxSize = action.payload;
    },
    setBoxName: (state, action) => {
      state.value.boxName = action.payload;
    },
    setContactPopup: (state, action) => {
      state.value.contactPopupIsOpen = action.payload;
    },
  },
});

export const {
  setSelectedAvatarRedux,
  setChildProfileRedux,
  setDisclaimerPopup,
  setCleanBoxPopup,
  setBoxStatus,
  setBoxAssociation,
  setBoxImage,
  setSelectedDiet,
  setBoxSize,
  setBoxName,
  setContactPopup,
} = mainReducerSlice.actions;
export default mainReducerSlice.reducer;
