import axiosBase from "../axios/baseUrl.js";
const userService = {
  register: async (registerForm) => {
    try {
      const { data } = await axiosBase.post("/user/register", registerForm);
      return data;
    } catch (err) {
      console.log(err, "error in register");
    }
  },
  verifyCode: async ({ email, code }) => {
    try {
      const { data } = await axiosBase.post("/user/verify", { email, code });
      return data;
    } catch (err) {
      console.log(err, "error in verify");
    }
  },
  login: async (email, password, box) => {
    try {
      const { data } = await axiosBase.post("/user/login", {
        email,
        password,
        box,
      });
      return data;
    } catch (err) {
      console.log(err, "error in login");
    }
  },
  checkBoxAssociation: async (boxIdentifier, profileId) => {
    try {
      const { data } = await axiosBase.post("/user/box-association", {
        boxIdentifier,
        profileId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in checkBoxAssociation");
    }
  },
  checkIfBoxExist: async (boxIdentifier) => {
    try {
      const { data } = await axiosBase.post("/user/check-box", {
        boxIdentifier,
      });
      return data;
    } catch (err) {
      console.log(err, "error in checkIfBoxExist");
    }
  },
  fetchGames: async (operatingSystem) => {
    try {
      const { data } = await axiosBase.get(
        `/user/fetchGames/${operatingSystem}`
      );
      return data;
    } catch (err) {
      console.log(err, "fetchGames error");
    }
  },
  verifyEmail: async (email) => {
    try {
      const { data } = await axiosBase.post("/user/verify-email", { email });
      return data;
    } catch (err) {
      console.log(err, "error in verifyEmail");
    }
  },
  checkVerificationDetails: async (email, code) => {
    try {
      const { data } = await axiosBase.post(
        "/user/check-verification-details",
        {
          email,
          code,
        }
      );
      return data;
    } catch (err) {
      console.log(err, "error in checkVerificationDetails");
    }
  },
  resetPassword: async (email, password, code) => {
    try {
      const { data } = await axiosBase.post("/user/reset-password", {
        email,
        password,
        code,
      });
      return data;
    } catch (err) {
      console.log(err, "error in resetPassword");
    }
  },
  logActivity: async (profileId) => {
    try {
      const { data } = await axiosBase.post("/user/log-activity", {
        profileId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in logActivity");
    }
  },
};

export default userService;
