import { useEffect, useState } from "react";
import "./style/allergies.css";
import { IconContext } from "react-icons";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Title from "./title";
import glutenRed from "../images/allergies/Gluten.png";
import eggRed from "../images/allergies/egg.png";
import fishRed from "../images/allergies/Fish.png";
import nutsRed from "../images/allergies/Nuts.png";
import lactoseRed from "../images/allergies/Lactose.png";
import noneRed from "../images/allergies/None.png";
import sesameRed from "../images/allergies/Sesame.png";
import soyRed from "../images/allergies/Soy.png";

import glutenWhite from "../images/allergies//white/gluten.png";
import eggWhite from "../images/allergies//white/egg.png";
import lactoseWhite from "../images/allergies//white/lactuse.png";
import fishWhite from "../images/allergies//white/fish.png";
import noneWhite from "../images/allergies//white/none.png";
import nutsWhite from "../images/allergies//white/Nuts.png";
import sesameWhite from "../images/allergies//white/Sesame.png";
import soyWhite from "../images/allergies//white/soy.png";
import Back from "./back";
import { setDisclaimerPopup } from "../redux/mainReducerSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import childService from "../services/childService";
import { useTranslation } from "react-i18next";
const allergyOptions = [
  { id: 1, name: "Eggs" },
  { id: 2, name: "Fish" },
  { id: 3, name: "Nuts / Peanuts" },
  { id: 4, name: "Gluten" },
  { id: 5, name: "Soy" },
  { id: 6, name: "Sesame" },
  { id: 7, name: "Lactose" },
  { id: 8, name: "None" },
];
const Allergies = () => {
  const [allergies, setAllergies] = useState(allergyOptions);
  const [selectedAllergies, setSelectedAllergies] = useState(["None"]);
  const [disclaimerIsChecked, setDisclaimerIsChecked] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";

  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }
  }, []);

  const handleAllergyClick = (allergyName) => {
    if (
      selectedAllergies.length === 1 &&
      selectedAllergies[0] === allergyName
    ) {
      setSelectedAllergies(["None"]);
      return;
    }

    if (allergyName === "None") {
      // If "None" is selected, remove all other values
      setSelectedAllergies([allergyName]);

      // Also remove all other values from the 'allergies' array
      setAllergies([allergyName]);
    } else {
      const firstRemoveNone = selectedAllergies.filter(
        (allergy) => allergy !== "None"
      );

      // Check if the clicked allergy is already selected
      const isSelected = firstRemoveNone.includes(allergyName);
      if (isSelected) {
        // If it's already selected, unselect it and remove it from both arrays
        const newSelectedAllergies = firstRemoveNone.filter(
          (allergy) => allergy !== allergyName
        );

        setSelectedAllergies(newSelectedAllergies);
        // Also remove it from the 'allergies' array
        setAllergies(newSelectedAllergies);
      } else {
        setSelectedAllergies([...firstRemoveNone, allergyName]);
        // Also add it to the 'allergies' array
        setAllergies([...firstRemoveNone, allergyName]);
      }
    }
  };

  const handleAllergies = async () => {
    if (!disclaimerIsChecked) {
      setDisclaimerIsChecked(true);
      dispatch(setDisclaimerPopup({ popupState: true }));
      return;
    }
    try {
      setDisableButtons(true);

      const childId = localStorage.getItem("profileId");

      if (!childId) {
        navigate("/kyc/login");
      }

      const allergies = allergyOptions.filter((allergy) => {
        return selectedAllergies.includes(allergy.name);
      });
      const response = await childService.updateAllergies(allergies, childId);
      if (response.message === "success") {
        navigate("/kyc/foods");
      }
    } catch (err) {
      console.log(err, "err in handleAllergies");
    }
  };

  return (
    <>
      <div className="general-container allergies-container">
        <Title para={null} mainTitle={t("Allergies")} />

        <div className="allergies-wrapper">
          <div className="allergies-row">
            <div className="allergy" onClick={() => handleAllergyClick("Eggs")}>
              <div
                className={
                  selectedAllergies.includes("Eggs")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={selectedAllergies.includes("Eggs") ? eggWhite : eggRed}
                  alt=""
                />
              </div>
              <div className="allergy-name">{t("Eggs")}</div>
            </div>
            <div className="allergy" onClick={() => handleAllergyClick("Fish")}>
              <div
                className={
                  selectedAllergies.includes("Fish")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={selectedAllergies.includes("Fish") ? fishWhite : fishRed}
                  alt=""
                />
              </div>
              <div className="allergy-name">{t("Fish")}</div>
            </div>
            <div
              className="allergy"
              onClick={() => handleAllergyClick("Nuts / Peanuts")}
            >
              <div
                className={
                  selectedAllergies.includes("Nuts / Peanuts")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={
                    selectedAllergies.includes("Nuts / Peanuts")
                      ? nutsWhite
                      : nutsRed
                  }
                  alt=""
                />
              </div>
              <div className="allergy-name">{t("Nuts / Peanuts")}</div>
            </div>
          </div>
          <div className="allergies-row">
            <div
              className="allergy"
              onClick={() => handleAllergyClick("Gluten")}
            >
              <div
                className={
                  selectedAllergies.includes("Gluten")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={
                    selectedAllergies.includes("Gluten")
                      ? glutenWhite
                      : glutenRed
                  }
                  alt=""
                />
              </div>
              <div className="allergy-name">{t("Gluten")}</div>
            </div>
            <div className="allergy" onClick={() => handleAllergyClick("Soy")}>
              <div
                className={
                  selectedAllergies.includes("Soy")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={selectedAllergies.includes("Soy") ? soyWhite : soyRed}
                  alt=""
                />
              </div>
              <div className="allergy-name">{t("Soy")}</div>
            </div>
            <div
              className="allergy"
              onClick={() => handleAllergyClick("Sesame")}
            >
              <div
                className={
                  selectedAllergies.includes("Sesame")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={
                    selectedAllergies.includes("Sesame")
                      ? sesameWhite
                      : sesameRed
                  }
                />
              </div>
              <div className="allergy-name">{t("Sesame")}</div>
            </div>
          </div>
          <div className="allergies-row">
            <div
              className="allergy"
              onClick={() => handleAllergyClick("Lactose")}
            >
              <div
                className={
                  selectedAllergies.includes("Lactose")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={
                    selectedAllergies.includes("Lactose")
                      ? lactoseWhite
                      : lactoseRed
                  }
                  alt=""
                />
              </div>
              <div className="allergy-name">{t("Lactose")}</div>
            </div>
            <div className="allergy" onClick={() => handleAllergyClick("None")}>
              <div
                className={
                  selectedAllergies.includes("None")
                    ? "allergy-circle chosen"
                    : "allergy-circle"
                }
              >
                <img
                  src={selectedAllergies.includes("None") ? noneWhite : noneRed}
                  alt=""
                />
              </div>
              <div className="allergy-name">{t("None")}</div>
            </div>
          </div>
        </div>
        <button
          className={"button-wrapper"}
          style={{ direction: languageDirection }}
          onClick={handleAllergies}
          disabled={disableButtons}
        >
          <div>{t("Next")}</div>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </button>
        <Back destination="diets" />
      </div>
    </>
  );
};

export default Allergies;
