import "./style/foods.css";
import Title from "./title";
import { IconContext } from "react-icons";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import childService from "../services/childService";
import Back from "./back";
import { useTranslation } from "react-i18next";
const foods = [
  { value: 1, label: "Muffins" },
  { value: 2, label: "Tortilla" },
  { value: 3, label: "Pizza" },
  { value: 4, label: "Sandwich" },
  { value: 5, label: "Pita" },
  { value: 6, label: "Buns" },
  { value: 7, label: "Eggs" },
  { value: 8, label: "Omelet" },
  { value: 9, label: "Toast bread" },
  { value: 10, label: "Vegetables" },
  { value: 11, label: "Rice" },
  { value: 12, label: "Pretzel" },
  { value: 13, label: "Pancakes" },
  { value: 14, label: "Belgian waffle" },
  { value: 15, label: "Pasta" },
  { value: 16, label: "Spaghetti" },
  { value: 17, label: "Noodles" },
  { value: 18, label: "Fritters" },
  { value: 19, label: "Meatballs" },
  { value: 20, label: "Fruits" },
  { value: 21, label: "Surprise box" },
  { value: 47, label: "Beef" },
  { value: 22, label: "Bread" },
  { value: 25, label: "Burritos" },
  { value: 40, label: "Cereals" },
  { value: 42, label: "Cheeses" },
  { value: 46, label: "Chicken" },
  { value: 29, label: "Dumplings" },
  { value: 31, label: "Empanadas" },
  { value: 30, label: "Falafel" },
  { value: 48, label: "Finger foods" },
  { value: 33, label: "Fish sticks" },
  { value: 39, label: "French toast" },
  { value: 37, label: "Frittata" },
  { value: 41, label: "Granola" },
  { value: 50, label: "Hamburger" },
  { value: 35, label: "Kebabs" },
  { value: 43, label: "Leftovers" },
  { value: 32, label: "Nuggets" },
  { value: 36, label: "Quiche" },
  { value: 34, label: "Roll-ups" },
  { value: 24, label: "Salads" },
  { value: 44, label: "Skewers" },
  { value: 49, label: "Snacks" },
  { value: 28, label: "Spring rolls" },
  { value: 27, label: "Sushi" },
  { value: 26, label: "Taco" },
  { value: 45, label: "Tofu dishes" },
  { value: 38, label: "Waffles" },
  { value: 23, label: "Wraps" },

  /*  */
  { value: 51, label: "Hamantaschen" },
  { value: 52, label: "Ban" },
  { value: 53, label: "Borax" },
  { value: 54, label: "Dishes for the holidays" },
  { value: 55, label: "Hummus" },
  { value: 56, label: "Happy Birthday" },
  { value: 57, label: "Everything Else" },
  { value: 58, label: "lasagna" },
  { value: 59, label: "Pastries" },
  { value: 60, label: "Mini pancakes" },
  { value: 61, label: "Hot dog" },
  { value: 62, label: "Donuts" },
  { value: 63, label: "Cigars" },
  { value: 64, label: "Cookies" },
  { value: 65, label: "Pies" },
  { value: 66, label: "Fries" },
  { value: 67, label: "Crackers" },
  { value: 68, label: "Vegetable skewers" },
  { value: 69, label: "Chicken skewers" },
  { value: 70, label: "Schnitzel" },
  { value: 71, label: "A Rustle" },
  { value: 72, label: "Stews" },
  { value: 73, label: "Stuffed" },
];

const Foods = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [selectedFoods, setSelectedFoods] = useState([]);
  const [unwantedFoods, setUnwantedFoods] = useState([]);
  const [childId, setChildId] = useState("");
  const [error, setError] = useState("");
  const [disableButtons, setDisableButtons] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const childIdFromStorage = localStorage.getItem("profileId");
    if (!childIdFromStorage) {
      navigate("/kyc/pick-user");
      return;
    } else {
      setChildId(childIdFromStorage);

      const language = localStorage.getItem("lang");
      if (language) {
        if (language !== i18n.language) {
          i18n.changeLanguage(language);
        }
      }
    }

    setUnwantedFoods(foods);
    setSelectedFoods([]);
  }, []);

  const handleRemoveFood = (value) => {
    setError("");
    const newSelectedFoods = selectedFoods.filter(
      (food) => food.value !== value
    );
    setSelectedFoods(newSelectedFoods);
    setUnwantedFoods([
      ...unwantedFoods,
      ...selectedFoods.filter((food) => food.value === value),
    ]);
  };
  const handleAddFood = (value) => {
    setError("");
    const newUnwantedFoods = unwantedFoods.filter(
      (food) => food.value !== value
    );
    setUnwantedFoods(newUnwantedFoods);

    setSelectedFoods([
      ...selectedFoods,
      ...unwantedFoods.filter((food) => food.value === value),
    ]);
  };

  const handleAll = (action) => {
    setError("");

    if (action === "remove") {
      setSelectedFoods([]);
      setUnwantedFoods([...foods]);
    } else {
      setSelectedFoods([...foods]);
      setUnwantedFoods([]);
    }
  };
  const handleFoodSelection = async () => {
    setError("");
    if (!selectedFoods.length) {
      setError(`${t("Please select at least one food")}`);
      return;
    }
    setDisableButtons(true);
    try {
      const setChildFoods = await childService.setChildFavoriteFoods(
        selectedFoods,
        childId
      );
      if (setChildFoods.message === "success") {
        navigate("/kyc/hubbies");
      } else {
        toast.error(`${t("Something went wrong")}`);
        console.log(setChildFoods, "setChildFoods");
        setDisableButtons(false);
      }
    } catch (error) {
      console.log(error, "error in food selection");
    }
  };
  return (
    <>
      <div className="general-container foods-container">
        <Title
          para={null}
          mainTitle={t("What kind of foods does your child like")}
        />
        <div className="secondary-title">
          {t("Deselect all your kids' food nemesis")}
        </div>
        <div className="choose-all-buttons">
          <button className="remove-all" onClick={() => handleAll("remove")}>
            {t("Remove all")}
          </button>
          <button className="select-all" onClick={() => handleAll("select")}>
            {t("Select all")}
          </button>
        </div>

        <div
          className="food-types"
          style={{
            justifyContent:
              languageDirection === "rtl" ? "flex-end" : "flex-start",
          }}
        >
          {selectedFoods.length > 0 &&
            selectedFoods.map((food) => {
              return (
                <div
                  className="food-wrapper"
                  style={{
                    flexDirection:
                      languageDirection === "rtl" ? "row-reverse" : "row",
                  }}
                  key={food.id}
                  onClick={() => handleRemoveFood(food.value)}
                >
                  <div className="remove">
                    <IconContext.Provider value={{ className: "remove-icon" }}>
                      <AiOutlineCloseCircle />
                    </IconContext.Provider>
                  </div>
                  <div className="food-name">{t(`${food?.label}`)}</div>
                </div>
              );
            })}
        </div>
        {unwantedFoods.length > 0 && selectedFoods.length > 0 && (
          <div className="dotted-line"></div>
        )}
        <div
          className="food-types unwanted-food-types"
          style={{
            justifyContent:
              languageDirection === "rtl" ? "flex-end" : "flex-start",
          }}
        >
          {unwantedFoods.length > 0 &&
            unwantedFoods.map((food) => {
              return (
                <div
                  className="food-wrapper unwanted-food-wrapper"
                  key={food.id}
                  onClick={() => handleAddFood(food.value)}
                >
                  <div className="food-name">{t(`${food?.label}`)}</div>
                </div>
              );
            })}
        </div>
        {error && <div className="error">{error}</div>}
        <button
          className="button-wrapper"
          style={{ direction: languageDirection }}
          onClick={handleFoodSelection}
          disabled={disableButtons}
        >
          <div>{t("Next")}</div>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </button>
        <Back destination="allergies" />
      </div>
    </>
  );
};

export default Foods;
