import Title from "./title";
import "./style/verification.css";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons/lib/cjs/iconContext";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import maskImage from "../images/code-verification-image2_prev_ui.png";
import userService from "../services/userService";
import { useTranslation } from "react-i18next";
const Verification = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const { email } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [code, setCode] = useState({
    firstDigit: "",
    secondDigit: "",
    thirdDigit: "",
    forthDigit: "",
  });
  const inputRefs = {
    firstDigit: useRef(null),
    secondDigit: useRef(null),
    thirdDigit: useRef(null),
    forthDigit: useRef(null),
  };
  const inputClicked = useRef(false); // Define inputClicked ref

  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }
  }, []);
  // const handleCodeChange = (e) => {
  //   setError("");
  //   setCode({ ...code, [e.target.name]: e.target.value });
  // };
  const handleCodeChange = (fieldName, value) => {
    setError("");
    setCode({ ...code, [fieldName]: value });

    // Focus on the current input field only if it hasn't been clicked before
    if (!inputClicked.current) {
      inputRefs[fieldName].current.focus();
      inputClicked.current = true; // Update the state to indicate that the input field has been clicked
    }

    // Move to the next input field if a single character is entered
    if (value.length === 1 && fieldName !== "forthDigit") {
      setTimeout(() => {
        inputRefs[nextFieldName(fieldName)].current.focus(); // Focus on the next input field after a short delay
      }, 0);
    }
  };

  const nextFieldName = (fieldName) => {
    switch (fieldName) {
      case "firstDigit":
        return "secondDigit";
      case "secondDigit":
        return "thirdDigit";
      case "thirdDigit":
        return "forthDigit";
      default:
        return fieldName;
    }
  };

  const handleVerification = async () => {
    setError("");
    if (
      !code.firstDigit ||
      isNaN(code.firstDigit) ||
      !code.secondDigit ||
      isNaN(code.secondDigit) ||
      !code.thirdDigit ||
      isNaN(code.thirdDigit) ||
      !code.forthDigit ||
      isNaN(code.forthDigit)
    )
      return setError(`${t("Please enter a valid code numbers only")}`);

    const codeString = parseFloat(
      `${code.firstDigit}${code.secondDigit}${code.thirdDigit}${code.forthDigit}`
    );

    try {
      const verifyCode = await userService.verifyCode({
        email,
        code: codeString,
      });
      if (verifyCode.message === "success") {
        navigate("/kyc/login");
      } else {
        setError(`${t("Wrong code, try again")}`);
        console.log(verifyCode, "verifyCode.message");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Title para={null} mainTitle={t("Code verification:")} />
      <div className="verification-container">
        <div className="verification-form-wrapper">
          {/* <form>
            <div className="code-verification">
              <input
                type="text"
                value={code.firstDigit}
                maxLength="1"
                name="firstDigit"
                size="1"
                min="0"
                max="9"
                onChange={handleCodeChange}
              />
            </div>
            <div className="code-verification">
              <input
                type="text"
                value={code.secondDigit}
                name="secondDigit"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                onChange={handleCodeChange}
              />
            </div>
            <div className="code-verification">
              <input
                type="text"
                value={code.thirdDigit}
                name="thirdDigit"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                onChange={handleCodeChange}
              />
            </div>
            <div className="code-verification">
              <input
                type="text"
                value={code.forthDigit}
                name="forthDigit"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                onChange={handleCodeChange}
              />
            </div>
          </form> */}

          <form>
            {Object.entries(code).map(([fieldName, fieldValue]) => (
              <div className="code-verification" key={fieldName}>
                <input
                  type="text"
                  value={fieldValue}
                  ref={inputRefs[fieldName]}
                  maxLength="1"
                  size="1"
                  min="0"
                  max="9"
                  onChange={(e) => handleCodeChange(fieldName, e.target.value)}
                />
              </div>
            ))}
          </form>
          <div className="user-email" style={{ direction: languageDirection }}>
            <p>{`${t("We sent it to")} ${email}`}</p>
          </div>
          <div
            className="wrong-email"
            onClick={() => navigate("/kyc/register")}
          >
            {t("Wrong email, try again")}
          </div>
          {error && <div className="error">{error}</div>}
          <div className="main-image">
            <img src={maskImage} alt="" className="mask" />
          </div>
          <div
            className="submit"
            onClick={handleVerification}
            style={{ direction: languageDirection }}
          >
            <button className="orange-btn">{t("Login")}</button>
            <div className="arrow">
              <IconContext.Provider value={{ className: "arrow-icon" }}>
                {languageDirection === "rtl" ? (
                  <MdKeyboardArrowLeft
                    className="arrow-icon"
                    style={{ fontSize: "30px" }}
                  />
                ) : (
                  <MdKeyboardArrowRight
                    className="arrow-icon"
                    style={{ fontSize: "30px" }}
                  />
                )}
              </IconContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
