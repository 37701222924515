import "./style/diets.css";
import Title from "./title";
import vegan from "../images/diets/Vegan.png";
import vegetarian from "../images/diets/Vegetarian.png";
import pescatarian from "../images/diets/Pescatarian.png";
import gluten_free from "../images/diets/Gluten_free.png";
import lactose_free from "../images/diets/Lactose_free.png";
import eats_everything from "../images/diets/Eats_everything.png";
import { IconContext } from "react-icons";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import childService from "../services/childService";
import { useDispatch } from "react-redux";
import { setSelectedDiet } from "../redux/mainReducerSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const dietOptions = [
  { id: 1, name: "Vegetarian", image: vegetarian },
  { id: 2, name: "Vegan", image: vegan },
  { id: 3, name: "Pescatarian", image: pescatarian },
  { id: 6, name: "Eats everything", image: eats_everything },
];

const Diets = () => {
  const [diets, setDiets] = useState(dietOptions);
  const [selectedLifestyle, setSelectedLifestyle] = useState("Eats everything");
  const navigate = useNavigate();
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }
  }, []);
  const handleDietsClick = async () => {
    const selectedDiet = diets
      .filter((diet) => diet.name === selectedLifestyle)
      .map(({ id, name }) => ({ id, name }))[0];
    try {
      const profileId = localStorage.getItem("profileId");
      const response = await childService.setLifestyle(selectedDiet, profileId);
      if (response.message === "success") {
        dispatch(setSelectedDiet(selectedLifestyle));
        navigate("/kyc/allergies");
      } else {
        toast.error(response.message);
        console.log(response, "response in diets");
      }
    } catch (error) {
      console.log(error, "error in diets");
    }
  };
  return (
    <>
      <div className="general-container diets-container">
        <Title para={null} mainTitle={t("Any diets")} />

        <div className="choose-diet">
          <div className="diet-row">
            {dietOptions.slice(0, 2).map((option) => (
              <div
                key={option.id}
                className="diet-type"
                onClick={() => setSelectedLifestyle(option.name)}
              >
                <div
                  className={
                    selectedLifestyle === option.name
                      ? "diet-image-circle chosen"
                      : "diet-image-circle"
                  }
                >
                  <img src={option.image} alt="" />
                </div>
                <div className="diet-name">{t(`${option.name}`)}</div>
              </div>
            ))}
          </div>
          <div className="diet-row">
            {dietOptions.slice(2).map((option) => (
              <div
                key={option.id}
                className="diet-type"
                onClick={() => setSelectedLifestyle(option.name)}
              >
                <div
                  className={
                    selectedLifestyle === option.name
                      ? "diet-image-circle chosen"
                      : "diet-image-circle"
                  }
                >
                  <img src={option.image} alt="" />
                </div>
                <div className="diet-name">{t(`${option.name}`)}</div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="button-wrapper"
          style={{ direction: languageDirection }}
          onClick={handleDietsClick}
        >
          <button>{t("Next")}</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: desktopView ? "30px" : "25px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: desktopView ? "30px" : "25px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Diets;
