import "./style/hubbies.css";
import Title from "./title";
import { useState, useEffect } from "react";
import Select from "react-select";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import Back from "./back";
import childService from "../services/childService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
const Hubbies = () => {
  const { t, i18n } = useTranslation();

  const mustPopular = [
    { value: 1, label: "Basketball" },
    { value: 2, label: "Soccer" },
    { value: 3, label: "Dancing" },
    { value: 4, label: "Baseball" },
    { value: 5, label: "Video games" },
    { value: 7, label: "Swimming" },
  ];
  const options = [
    { value: 1, label: `${t("Basketball")}` },
    { value: 2, label: `${t("Soccer")}` },
    { value: 3, label: `${t("Dancing")}` },
    { value: 4, label: `${t("Baseball")}` },
    { value: 5, label: `${t("Video games")}` },
    { value: 7, label: `${t("Swimming")}` },
    { value: 8, label: `${t("Riding bikes")}` },
    { value: 9, label: `${t("Skateboarding")}` },
    { value: 10, label: `${t("Roller skating")}` },
    { value: 11, label: `${t("Reading books")}` },
    { value: 12, label: `${t("Drawing")}` },
    { value: 13, label: `${t("Musical instruments")}` },
    { value: 14, label: `${t("Singing/theater/acting")}` },
    { value: 15, label: `${t("Legos & blocks")}` },
    { value: 16, label: `${t("Board games")}` },
    { value: 17, label: `${t("Collecting things")}` },
    { value: 18, label: `${t("Photography")}` },
    { value: 19, label: `${t("Gardening/planting")}` },
    { value: 20, label: `${t("Cooking/baking")}` },
    { value: 21, label: `${t("Camping/hiking")}` },
    { value: 22, label: `${t("Fishing")}` },
  ];

  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";

  const [selected, setSelected] = useState([]);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [childId, setChildId] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const childIdLocal = localStorage.getItem("profileId");

    const fetchChildName = async () => {
      try {
        const response = await childService.getChildName(childIdLocal);

        if (response.message === "success") {
          setName(response.firstName);
        } else {
          console.log(response, "error fetching child name");
        }
      } catch (err) {
        console.log(err, "error fetching child name");
      }
    };
    if (!childIdLocal) {
      navigate("/kyc/login");
    } else {
      setChildId(childIdLocal);
      fetchChildName(childIdLocal);

      const language = localStorage.getItem("lang");
      if (language) {
        if (language !== i18n.language) {
          i18n.changeLanguage(language);
        }
      }
    }
  }, []);
  const handleSelectChanges = (selectedOption) => {
    setError("");
    // check if selectedOption is already in selected array
    const isExist = selected.find(
      (item) => item.value === selectedOption.value
    );
    if (isExist) {
      // if exist we remove it
      const filtered = selected.filter(
        (item) => item.value !== selectedOption.value
      );
      setSelected(filtered);
    } else {
      //else we add it to the array
      setSelected([...selected, selectedOption]);
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      width: "70vw",
      maxWidth: "400px",
      borderRadius: "4px",
      borderBottom: "2px solid #3C4964", // Replace --color-name with your color variable
      marginBottom: "10px",
      "&:hover": {
        borderBottom: "2px solid #3C4964", // Remove the border on hover
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3C4964",
      direction: languageDirection,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    menu: (provided) => ({
      ...provided,
      direction: languageDirection,
    }),
  };

  const RemoveHubbies = (option) => {
    setError("");
    const filtered = selected.filter((item) => item.value !== option.value);
    setSelected(filtered);
  };

  const handleHubbies = async () => {
    if (!selected.length) {
      setError(`${t("Please select at least one hubby")}`);
      return;
    }
    try {
      const insertHubbies = await childService.insertHubbies(selected, childId);
      if (insertHubbies.message === "success") {
        navigate("/kyc/education");
      } else {
        console.log(insertHubbies, "error in hubby selection");
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log(err, "error in hubby selection");
    }
  };
  return (
    <>
      <div className="general-container hubbies-container">
        <Title
          para={null}
          mainTitle={
            languageDirection === "ltr"
              ? name
                ? `${t("What does")} ${name} 
             ${t("do for fun")}`
                : `${t("What do you do for fun")}`
              : // language is hebrew
              name
              ? `${t("what hubbies")} ${name} `
              : `${t("whats your hubbies")}`
          }
        />
        <div className="select-wrapper">
          <Select
            options={options}
            onChange={handleSelectChanges}
            value={selected}
            placeholder={t("Select")}
            styles={customStyles} // Apply the custom styles
          />
        </div>
        {selected.length > 0 && (
          <div
            className="display-selected"
            style={{
              justifyContent:
                languageDirection === "rtl" ? "flex-end" : "flex-start",
            }}
          >
            {selected.map((option) => {
              return (
                <div
                  className="selected-option-wrapper"
                  style={{
                    flexDirection:
                      languageDirection === "rtl" ? "row-reverse" : "row",
                  }}
                  key={option.value}
                  onClick={() => RemoveHubbies(option)}
                >
                  <div className="remove">
                    <IconContext.Provider value={{ className: "remove-icon" }}>
                      <AiOutlineCloseCircle />
                    </IconContext.Provider>
                  </div>

                  <div className="option">{t(`${option?.label}`)}</div>
                </div>
              );
            })}
          </div>
        )}

        <div className="popular-choices">
          <h1 style={{ direction: languageDirection }}>{t("Most popular")}</h1>
          <div
            className="popular-choice-wrapper"
            style={{
              justifyContent:
                languageDirection === "rtl" ? "flex-end" : "flex-start",
            }}
          >
            {mustPopular.map((option) => {
              return (
                <div
                  className="choice"
                  key={option.value}
                  onClick={() => handleSelectChanges(option)}
                >
                  {t(`${option?.label}`)}
                </div>
              );
            })}
          </div>
        </div>
        {error && <div className="error">{error}</div>}
        <div
          className="button-wrapper"
          onClick={handleHubbies}
          style={{ direction: languageDirection }}
          // style={{ margin: error ? "50px 0 0 0" : "250px 0 0 0" }}
        >
          <button className="next-button">{t("Next")}</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </div>
        <Back destination="foods" />
      </div>
    </>
  );
};

export default Hubbies;
