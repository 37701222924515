import { IconContext } from "react-icons/lib/cjs/iconContext";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import Title from "./title";
import "./style/register-login.css";
import { useEffect, useState } from "react";
import utils from "../utils/regex";
import userService from "../services/userService";
import childService from "../services/childService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const Login = () => {
  const navigate = useNavigate();
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [error, setError] = useState("");
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const boxAssociation = useSelector(
    (state) => state.mainReducer.value.boxAssociation
  );
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }
  }, []);

  const checkUserCurrentStep = (status) => {
    console.log(status, "status of kyc");
    switch (status) {
      case "created":
        navigate("/kyc/diets");
        break;
      case "lifestyle":
        navigate("/kyc/allergies");

        break;
      case "allergies":
        navigate("/kyc/foods");
        break;
      case "favorite_food":
        navigate("/kyc/hobbies");
        break;
      case "hobbies":
        navigate("/kyc/education");
        break;

      default:
        console.log(status, "status of kyc no status match ");
        return false;
    }
  };
  const handleFormChange = (e) => {
    setError("");
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const handleFormSubmission = async () => {
    if (!loginForm.email || !utils.emailRegex.test(loginForm.email)) {
      setError(`${t("Please enter a valid email")}`);
      return;
    }
    if (!loginForm.password) {
      setError(`${t("Please enter a password")}`);
      return;
    }
    if (loginForm.password.length < 5) {
      setError(`${t("Password must be at least 5 characters")}`);
      return;
    }
    const box = localStorage.getItem("box");
    const boxColor = box ? box.substring(0, 3) : null;

    try {
      const login = await userService.login(
        loginForm.email,
        loginForm.password,
        box
      );

      if (login.message === "success") {
        localStorage.setItem("token", login.token);
        console.log(login, "login");
        const profileId = localStorage.getItem("profileId");
        if (profileId) {
          // if profileId exists and box is assigned to one of the family members
          console.log(boxAssociation, "boxAssociation");
          if (
            boxAssociation === "box assigned to another family member" ||
            boxAssociation === "box assigned to current profile"
          ) {
            // if box assigned to current household member or current profile
            if (login?.profileId) {
              // check if kyc is finished
              const finishedKyc = await childService.checkKycStatus(
                login.profileId
              );
              console.log(finishedKyc, "finishedKyc 1");
              if (finishedKyc.message === "success") {
                if (finishedKyc.kycStatus === "kyc-finished") {
                  if (boxColor !== null) {
                    window.location.href = `https://my.getyoopi.com/${encodeURIComponent(
                      login.token
                    )}/${encodeURIComponent(
                      login.profileId
                    )}/${encodeURIComponent(boxColor)}`;
                  } else {
                    console.log("boxColor is null login page");
                  }
                } else {
                  // move user to the current step in kyc
                  checkUserCurrentStep(finishedKyc.kycStatus);
                }
              }
            }
          } else {
            // box not registered
            if (login?.profileId) {
              localStorage.setItem("profileId", login.profileId);

              const finishedKyc = await childService.checkKycStatus(
                login.profileId
              );
              console.log(finishedKyc, "finishedKyc 2");

              if (finishedKyc.message === "success") {
                if (finishedKyc.kycStatus === "kyc-finished") {
                  window.location.href = `http://my.getyoopi.com/${encodeURIComponent(
                    login.token
                  )}/${encodeURIComponent(
                    login.profileId
                  )}/${encodeURIComponent(boxColor)}`;
                } else {
                  // move user to the current step in kyc
                  checkUserCurrentStep(finishedKyc.kycStatus);
                }
              }
            } else {
              navigate("/kyc/pick-user");
            }
          }
        } else {
          if (boxAssociation === "box exist in db") {
            // check if box is associated with current family member
            const boxIdentifier = localStorage.getItem("box");
            const householdAssociation =
              await childService.checkHouseholdAssociation(boxIdentifier);
            if (
              householdAssociation.message === "box assigned to another account"
            ) {
              localStorage.removeItem("token");
              setError(`${t("This YOOPI box assigned to another account")}`);
              return;
            } else if (
              householdAssociation.message ===
              "box assigned to another family member"
            ) {
              if (householdAssociation?.profileId) {
                localStorage.setItem(
                  "profileId",
                  householdAssociation.profileId
                );

                const finishedKyc = await childService.checkKycStatus(
                  householdAssociation.profileId
                );
                console.log(finishedKyc, "finishedKyc 3");
                if (finishedKyc.message === "success") {
                  if (finishedKyc.kycStatus === "kyc-finished") {
                    window.location.href = `http://my.getyoopi.com/${encodeURIComponent(
                      login.token
                    )}/${encodeURIComponent(
                      householdAssociation.profileId
                    )}/${encodeURIComponent(boxColor)}`;
                    return;
                  } else {
                    // move user to the current step in kyc
                    checkUserCurrentStep(finishedKyc.kycStatus);
                  }
                }
              }
            }
            {
            }
          }
          if (login?.profileId) {
            localStorage.setItem("profileId", login.profileId);

            const finishedKyc = await childService.checkKycStatus(
              login.profileId
            );
            console.log(finishedKyc, "finishedKyc 4");
            if (finishedKyc.message === "success") {
              if (finishedKyc.kycStatus === "kyc-finished") {
                window.location.href = `http://my.getyoopi.com/${encodeURIComponent(
                  login.token
                )}/${encodeURIComponent(login.profileId)}/${encodeURIComponent(
                  boxColor
                )}`;
                return;
              } else {
                // move user to the current step in kyc
                checkUserCurrentStep(finishedKyc.kycStatus);
              }
            }
          } else {
            // box isnt associated with any family member
            navigate("/kyc/pick-user");
          }
          // box isnt associated with any family member
          // move to pick user page
        }
      } else if (login.message === "Email not registered") {
        toast.error(t(login.message));
        navigate("/kyc/register");
      } else {
        toast.error(login.message);
      }
    } catch (err) {
      console.log(err, "err in login");
    }
  };
  return (
    <>
      <div className="login-container">
        <Title para={null} mainTitle={t("Hello again!")} />
        <div className="login-form-wrapper">
          <form>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                type="email"
                name="email"
                value={loginForm.email}
                placeholder={t("Email")}
                onChange={handleFormChange}
              />
            </div>
            <div
              className="form-control"
              style={{ direction: languageDirection }}
            >
              <input
                type="password"
                name="password"
                value={loginForm.password}
                placeholder={t("Password")}
                onChange={handleFormChange}
              />
            </div>
            <div
              className="forgot-password"
              style={{ direction: languageDirection }}
              onClick={() => navigate("/kyc/verifyEmail")}
            >
              <span>{t("Forgot your password")}</span>
            </div>
            {error && (
              <div className="error" style={{ marginTop: "50px" }}>
                {error}
              </div>
            )}
            <div
              onClick={handleFormSubmission}
              className="form-submission"
              style={{
                marginTop: error ? "120px" : "170px",
                direction: languageDirection,
              }}
            >
              <input type="button" value={t("Next")} />
              <div className="arrow">
                <IconContext.Provider value={{ className: "arrow-icon" }}>
                  {languageDirection === "rtl" ? (
                    <MdKeyboardArrowLeft
                      className="arrow-icon"
                      style={{ fontSize: "30px" }}
                    />
                  ) : (
                    <MdKeyboardArrowRight
                      className="arrow-icon"
                      style={{ fontSize: "30px" }}
                    />
                  )}
                </IconContext.Provider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
