import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "./header";
// import "./style/welcome.css";
import "./style/checkAccount.css";
import yellowBox from "../images/boxes/box_yellow.png";
import Title from "./title";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const CheckAccount = () => {
  const boxStatus = useSelector((state) => state.mainReducer.value.boxStatus);
  const boxImage = useSelector((state) => state.mainReducer.value.boxImage);
  const boxName = useSelector((state) => state.mainReducer.value.boxName);
  const boxAssociation = useSelector(
    (state) => state.mainReducer.value.boxAssociation
  );

  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [error, setError] = useState("");
  const boxSize = useSelector((state) => state.mainReducer.value.boxSize);
  // const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const navigate = useNavigate();
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, []);

  const handleNewFamily = () => {
    // if box status true that means box exist in db
    if (boxStatus) {
      if (boxAssociation === "box exist in db")
        setError("box Registered please login");
      return;
    }
    navigate("/kyc/register");
  };
  return (
    <>
      <div className="check-account-container">
        <Title
          para={t("Hello_i_am")}
          mainTitle={t(`${boxName}`)}
          boxSize={boxSize}
        />

        <div className="main-image">
          <div className="main-image-frame">
            <img src={boxImage} alt="" />
          </div>
        </div>
        <div className="buttons">
          <div className="new-family" onClick={handleNewFamily}>
            <button>{t("New Family")}</button>
          </div>
          <div className="existing-user" onClick={() => navigate("/kyc/login")}>
            <button>{t("Existing User")}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckAccount;
