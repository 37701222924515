import axiosBase from "../axios/baseUrl.js";

const childService = {
  createChildProfile: async (
    firstName,
    year,
    month,
    avatar,
    avatarName,
    boxIdentifier
  ) => {
    try {
      const { data } = await axiosBase.post(
        "/child/create-new-profile",
        firstName,
        year,
        month,
        avatar,
        avatarName,
        boxIdentifier
      );
      return data;
    } catch (err) {
      console.log(err, "error in createNewBoxProfile");
    }
  },

  getAllChildProfiles: async () => {
    try {
      const { data } = await axiosBase.get("/child/get-all");
      return data;
    } catch (err) {
      console.log(err, "error in getAllChildren");
    }
  },
  setChildFavoriteFoods: async (selectedFoods, childId) => {
    try {
      const { data } = await axiosBase.post("/child/favorite-food", {
        selectedFoods,
        childId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in setChildFavoriteFoods");
    }
  },
  getChildName: async (childId) => {
    try {
      const { data } = await axiosBase.get(`/child/get-name/${childId}`);
      return data;
    } catch (err) {
      console.log(err, "error in getChildName");
    }
  },
  insertHubbies: async (hubbies, childId) => {
    try {
      const { data } = await axiosBase.post("/child/hubbies", {
        hubbies,
        childId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in insertHubbies");
    }
  },
  updateAllergies: async (allergies, childId) => {
    try {
      if (!allergies.length) {
        return "no allergies";
      }
      const { data } = await axiosBase.post("/child/allergies", {
        allergies,
        childId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in updateAllergies");
    }
  },
  updateEducation: async (education) => {
    try {
      const { data } = await axiosBase.post("/child/education", { education });
      return data;
    } catch (err) {
      console.log(err, "error in updateEducation");
    }
  },
  setLifestyle: async (lifestyle, profileId) => {
    try {
      const { data } = await axiosBase.post("/child/lifestyle", [
        lifestyle,
        profileId,
      ]);
      return data;
    } catch (err) {
      console.log(err, "error in setLifestyle");
    }
  },
  addBoxToExistingAccount: async (childId, boxIdentifier) => {
    try {
      const { data } = await axiosBase.post("/child/add-box", {
        childId,
        boxIdentifier,
      });
      return data;
    } catch (err) {
      console.log(err, "error in addBoxToExistingAccount");
    }
  },
  checkHouseholdAssociation: async (boxIdentifier) => {
    try {
      const { data } = await axiosBase.post("/child/household-box", {
        boxIdentifier,
      });
      return data;
    } catch (err) {
      console.log(err, "error in checkHouseholdAssociation");
    }
  },
  checkKycStatus: async (profileId) => {
    try {
      const { data } = await axiosBase.get(`/child/kyc-finished/${profileId}`);
      return data;
    } catch (err) {
      console.log(err, "error in checkIfUserFinishedKyc");
    }
  },
};

export default childService;
