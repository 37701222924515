import "./style/disclaimer.css";
import "./style/boxPopup.css";
import { useMediaQuery } from "react-responsive";
import { IconContext } from "react-icons";
import { MdKeyboardArrowRight } from "react-icons/md";
import { setDisclaimerPopup } from "../redux/mainReducerSlice";
import { setCleanBoxPopup } from "../redux/mainReducerSlice";
import { useDispatch } from "react-redux";
import stars from "../images/clean.png";

const BoxPopup = () => {
  const dispatch = useDispatch();

  const breakPoint = useMediaQuery({ query: "(max-width: 450px)" });
  return (
    <>
      <div
        className="disclaimer-container clean-box-container"
        style={{ position: breakPoint ? "absolute" : "fixed" }}
      >
        <div className="stars">
          <img src={stars} alt="" />
        </div>
        <div className="clean-box">
          <h2>How to clean your box: </h2>
          <p>
            <span>Yes, you can clean it in the dishwasher!</span> (But please
            use the top tray only). The box has been rigorously tested at 65°C
            for 90 minutes.
          </p>
          <p>
            <span>Preventing Mold:</span>
            To prevent mold growth, follow these steps:
          </p>

          <ul>
            <li>
              Ensure the box is completely dry after removing leftovers and fat.
            </li>
            <li>
              If the box is in a humid environment, leave it open until dry
              before refilling.
            </li>
            <li>
              Note: If mold develops on the product, the warranty will be
              voided.
            </li>
          </ul>
        </div>
        <div
          className="button-wrapper"
          onClick={() => dispatch(setCleanBoxPopup({ popupState: false }))}
        >
          <button>Got it!</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              <MdKeyboardArrowRight />
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxPopup;
