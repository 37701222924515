import Title from "./title";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import childService from "../services/childService";
import schoolBlue from "../images/school/blue/school.png";
import schoolWhite from "../images/school/white/schoolW.png";
import gardenBlue from "../images/school/blue/kindergarden.png";
import gardenWhite from "../images/school/white/kindergardenW.png";
import otherBlue from "../images/school/blue/other.png";
import otherWhite from "../images/school/white/otherW.png";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { IconContext } from "react-icons";
import "./style/education.css";
import { useMediaQuery } from "react-responsive";
import Back from "./back";
import { useTranslation } from "react-i18next";
const Education = () => {
  const desktop = useMediaQuery({
    query: "(min-width: 750px)",
  });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const navigate = useNavigate();
  const [selection, setSelection] = useState("");
  const [firstName, setFirstName] = useState("");
  const [includeMeals, setIncludeMeals] = useState("include-meals");
  const [duration, setDuration] = useState("Long day");
  const [childIdLocal, setChildIdLocal] = useState(0);
  // const [showSelectField, setShowSelectField] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    const fetchChildName = async () => {
      try {
        const response = await childService.getChildName(childIdLocal);

        if (response.message === "success") {
          setFirstName(response.firstName);
        } else {
          console.log(response, "error fetching child name in school");
        }
      } catch (err) {
        console.log(err, "error fetching child name school");
      }
    };

    const childIdLocal = localStorage.getItem("profileId");
    if (!childIdLocal) {
      navigate("/kyc/login");
    } else {
      fetchChildName(childIdLocal);
      setChildIdLocal(childIdLocal);
      const language = localStorage.getItem("lang");
      if (language) {
        if (language !== i18n.language) {
          i18n.changeLanguage(language);
        }
      }
    }
  }, []);

  const handleEducationClick = async () => {
    setError("");

    if (selection === "") {
      setError(`${t("Please select an option")}`);
      return;
    }

    try {
      //
      const educationData = {};
      educationData.school = selection;
      educationData.childId = childIdLocal;
      if (selection !== "Other") {
        // if  "School" ||  "kindergarten" we add meals and duration

        educationData.meals = includeMeals;
        educationData.duration = duration;
      }

      const response = await childService.updateEducation(educationData);
      if (response.message === "success") {
        // home page on main site
        const box = localStorage.getItem("box");

        const boxColor = box ? box.substring(0, 3) : null;
        if (boxColor !== null) {
          window.location.href = `https://my.getyoopi.com/${encodeURIComponent(
            localStorage.getItem("token")
          )}/${encodeURIComponent(childIdLocal)}/${encodeURIComponent(
            boxColor
          )}`;
        } else {
          console.log("box color is null in handleEducationClick");
        }
      } else {
        console.log(response, "error in handleEducationClick");
        setError(`${t("Something went wrong, please try again")}`);
      }
    } catch (err) {
      console.log(err, "err in handleEducationClick");
    }
  };

  return (
    <>
      <div className="general-container education-container">
        <Title
          para={null}
          mainTitle={
            languageDirection === "ltr"
              ? firstName
                ? `${t("Where does")} ${firstName} ${t("go to")} `
                : `${t("Where do you go to")} `
              : // language is hebrew

                `  ${t("goes to")}`
          }
        />

        <div className="selection">
          {desktop ? (
            <>
              {/* desktop view */}
              <div
                className="selection-row"
                style={{
                  flexDirection:
                    languageDirection === "rtl" ? "row-reverse" : "row",
                }}
              >
                <div className="choice" onClick={() => setSelection("School")}>
                  <div
                    className={
                      selection === "School"
                        ? "choice-img selected"
                        : "choice-img"
                    }
                  >
                    <img
                      src={selection === "School" ? schoolWhite : schoolBlue}
                      alt=""
                    />
                  </div>
                  <div className="choice-title">{t("School")}</div>
                </div>
                <div
                  className="choice"
                  onClick={() => setSelection("kindergarten")}
                >
                  <div
                    className={
                      selection === "kindergarten"
                        ? "choice-img selected"
                        : "choice-img"
                    }
                  >
                    <img
                      src={
                        selection === "kindergarten" ? gardenWhite : gardenBlue
                      }
                      alt=""
                    />
                  </div>
                  <div className="choice-title">{t("kindergarten")}</div>
                </div>
                <div className="choice" onClick={() => setSelection("Other")}>
                  <div
                    className={
                      selection === "Other"
                        ? "choice-img selected"
                        : "choice-img"
                    }
                  >
                    <img
                      src={selection === "Other" ? otherWhite : otherBlue}
                      alt=""
                    />
                  </div>
                  <div className="choice-title">{t("Other")}</div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* mobile view */}
              <div
                className="selection-row"
                style={{
                  flexDirection:
                    languageDirection === "rtl" ? "row-reverse" : "row",
                }}
              >
                <div className="choice" onClick={() => setSelection("School")}>
                  <div
                    className={
                      selection === "School"
                        ? "choice-img selected"
                        : "choice-img"
                    }
                  >
                    <img
                      src={selection === "School" ? schoolWhite : schoolBlue}
                      alt=""
                    />
                  </div>
                  <div className="choice-title">{t("School")}</div>
                </div>
                <div
                  className="choice"
                  onClick={() => setSelection("kindergarten")}
                >
                  <div
                    className={
                      selection === "kindergarten"
                        ? "choice-img selected"
                        : "choice-img"
                    }
                  >
                    <img
                      src={
                        selection === "kindergarten" ? gardenWhite : gardenBlue
                      }
                      alt=""
                    />
                  </div>
                  <div className="choice-title">{t("kindergarten")}</div>
                </div>
              </div>
              <div className="selection-row">
                <div className="choice" onClick={() => setSelection("Other")}>
                  <div
                    className={
                      selection === "Other"
                        ? "choice-img selected"
                        : "choice-img"
                    }
                  >
                    <img
                      src={selection === "Other" ? otherWhite : otherBlue}
                      alt=""
                    />
                  </div>
                  <div className="choice-title">{t("Other")}</div>
                </div>
              </div>
            </>
          )}
        </div>

        {(selection === "School" || selection === "kindergarten") && (
          <>
            <div
              className={
                desktop
                  ? "educational-data d-flex flex-row"
                  : "educational-data d-flex flex-column"
              }
            >
              <div className="hours">
                <select
                  name="duration"
                  id="duration"
                  style={{ direction: languageDirection }}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <option value="Long day (till 17:00)">{t("Long day")}</option>
                  <option value="Short day">{t("Short day")} </option>
                </select>
              </div>
              <div className="meals">
                <select
                  name="meals"
                  id="meals"
                  style={{ direction: languageDirection }}
                  onChange={(e) => setIncludeMeals(e.target.value)}
                >
                  <option value="include-meals">
                    {" "}
                    {t("Including meals")}{" "}
                  </option>
                  <option value="dont-include-meals">
                    {t("Not Including meals")}
                  </option>
                </select>
              </div>
            </div>
          </>
        )}

        {error && <div className="error">{error}</div>}

        <div
          onClick={handleEducationClick}
          className={
            selection !== "" ? "button-wrapper" : "button-wrapper disabled"
          }
          style={{
            margin: error ? "50px 0 0 0" : "80px 0 0 0",
            direction: languageDirection,
          }}
        >
          <button className="next-button">{t("Next")}</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </div>
        <Back destination="hubbies" />
      </div>
    </>
  );
};

export default Education;
