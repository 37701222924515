import Title from "./title";
import "./style/kidsZone.css";
import main from "../images/kids-zone/main.png";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import userService from "../services/userService";
import imageBaseUrl from "../config";
import placeholderImage from "../images/Bluey.png";
import { useNavigate } from "react-router-dom";
const KidsZone = () => {
  const mobile = useMediaQuery({ query: "(max-width: 750px)" });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [games, setGames] = useState([]);
  const [error, setError] = useState("");
  const gameCategories = [
    { id: 1, name: "What We're Playing" },
    { id: 2, name: "Must-Play Games" },
    { id: 3, name: "More Games You Might Like" },
    { id: 4, name: "Arcade Games" },
    { id: 5, name: "Top Free Games" },
    { id: 6, name: "Top Paid Games" },
    { id: 7, name: "Educational Games" },
    { id: 8, name: "Multiplayer games" },
    { id: 9, name: "Can You Rule the Leaderboard?" },
    { id: 10, name: "Find Your Next Favourite Game" },
    { id: 11, name: "New Games We Love" },
    { id: 12, name: "New Phone? Play These!" },
    { id: 13, name: "Now Trending" },
    { id: 14, name: "Action Games for You" },
    { id: 15, name: "Popular Games" },
    { id: 16, name: "Our Favourite Free Games" },
    { id: 17, name: "Racing Games for You" },
    { id: 18, name: "Games Kids Will Love" },
    { id: 19, name: "Play Online With Friends" },
    { id: 20, name: "Big Name Games" },
    { id: 21, name: "Safe Video Streaming" },
  ];
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Check if the user agent contains any iOS identifiers
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    // Check if the user agent contains any Apple desktop identifiers
    const isAppleDesktop = /Macintosh/.test(userAgent);

    // If the user is not iOS or Apple desktop, assume they are on an Android device
    const isAndroid = !isIOS && !isAppleDesktop;
    const operatingSystem = isAndroid ? "android" : "ios";
    const fetchGames = async () => {
      try {
        const response = await userService.fetchGames(operatingSystem);
        if (response.message === "success") {
          setGames(response.games);
        } else {
          setError(response.message);
          setGames([]);
        }
      } catch (err) {
        console.log(err, "fetchGames error");
      }
    };
    fetchGames();
  }, []);
  const handleBack = () => {
    const box = localStorage.getItem("box");
    navigate(`/kyc/welcome/box?cid=${box}`);
  };
  return (
    <>
      <div className="welcome-container kids-zone-container">
        {/* <Title para={null} mainTitle={t("Kids Zone")} /> */}
        <div className="kids-zone-main">
          <div className="kids-zone-img">
            <img src={main} alt="" />

            {mobile ? (
              <>
                <div className="games-container-mobile">
                  {gameCategories.map((category) => {
                    // Filter games that belong to the current category
                    const categoryGames = games.filter(
                      (game) => game.category === category.name
                    );

                    if (categoryGames.length > 0) {
                      return (
                        <>
                          <div className="category-games">
                            <h2>{category.name}</h2>

                            {categoryGames.map((game) => (
                              <div className="game-mobile">
                                <div
                                  onClick={() =>
                                    window.open(game.link, "_blank")
                                  }
                                  className="game-image-mobile"
                                  style={{
                                    backgroundImage: `url(${imageBaseUrl}game-images/${game.image})`,
                                  }}
                                ></div>
                                <div
                                  key={game.id}
                                  className="game-content-mobile"
                                >
                                  <h4
                                    onClick={() =>
                                      window.open(game.link, "_blank")
                                    }
                                  >
                                    {game?.name}
                                  </h4>
                                  <p>
                                    {game.description.length > 30
                                      ? game.description.slice(0, 30) + "..."
                                      : game.description}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      );
                    } else {
                      // No games for this category, don't render anything
                      return null;
                    }
                  })}
                </div>
              </>
            ) : (
              <>
                {/* desktop view */}
                <div className="games-container">
                  {gameCategories.map((category) => {
                    const categoryGames = games.filter(
                      (game) => game.category === category.name
                    );
                    if (categoryGames.length > 0) {
                      return (
                        <>
                          <div className="category-games-desktop">
                            <h2>{category.name}</h2>
                            <div className="game-row">
                              {categoryGames.map((game) => (
                                <div className="game" key={game.id}>
                                  <div
                                    className="game-image"
                                    onClick={() =>
                                      window.open(game.link, "_blank")
                                    }
                                    style={{
                                      backgroundImage: `url(${imageBaseUrl}game-images/${game.image})`,
                                    }}
                                  ></div>
                                  <div className="game-content">
                                    <h4
                                      onClick={() =>
                                        window.open(game.link, "_blank")
                                      }
                                    >
                                      {game?.name}
                                    </h4>
                                    <p>{game?.description}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </>
            )}

            {error && <p>{error}</p>}
            <div className="back-btn">
              <button onClick={handleBack}>{t("Back")}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KidsZone;
