import "./style/changeAvatar.css";
import Title from "./title";
import lemon from "../images/avatar/new/web/001-lemon-new.png";
import crab from "../images/avatar/new/web/crab.png";
import summer from "../images/avatar/new/web/007-summer-1.png";
import whale from "../images/avatar/new/web/008-whale-1.png";
import lemonade from "../images/avatar/new/web/009-lemonade-1.png";
import watermelon from "../images/avatar/new/web/012-watermelon-1.png";
import orange from "../images/avatar/new/web/014-orange-1.png";
import pineapple from "../images/avatar/new/web/015-pineapple-1.png";
import strawberry from "../images/avatar/new/web/016-strawberry-1.png";
import { useEffect, useState, useRef, useCallback } from "react";
import { IconContext } from "react-icons/lib";
import { BsCameraFill } from "react-icons/bs";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import { setSelectedAvatarRedux } from "../redux/mainReducerSlice";
import { useTranslation } from "react-i18next";
const avatars = [
  { id: 1, image: lemon, name: "lemon" },
  { id: 2, image: crab, name: "crab" },
  { id: 3, image: summer, name: "summer" },
  { id: 4, image: whale, name: "whale" },
  { id: 5, image: lemonade, name: "lemonade" },
  { id: 6, image: watermelon, name: "watermelon" },
  { id: 7, image: orange, name: "orange" },
  { id: 8, image: pineapple, name: "pineapple" },
  { id: 9, image: strawberry, name: "strawberry" },
];

const ChangeAvatar = () => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [selectedAvatarName, setSelectedAvatarName] = useState("Lemon");
  const [isOpen, setIsOpen] = useState(false);
  const [isPictureTaken, setIsPictureTaken] = useState(false); // This state is used to determine whether to display the avatar from the camera

  const [listOfAvatars, setListOfAvatars] = useState(avatars);
  const webcamRef = useRef(null);
  const avatarDetailsRedux = useSelector(
    (state) => state.mainReducer.value.selectedAvatar
  );
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }
  }, []);
  useEffect(() => {
    // if chosen exist we assign it to the selectedAvatar
    if (avatarDetailsRedux.avatarSrc) {
      setSelectedAvatar(avatarDetailsRedux.avatarSrc);
      setSelectedAvatarName(avatarDetailsRedux.avatarName);
    } else {
      // if not we assign the first avatar to the selectedAvatar
      setSelectedAvatar(avatars[0].image);
    }
  }, [avatarDetailsRedux]);

  const capture = useCallback(
    (e) => {
      e.stopPropagation(); // Stop event propagation to ensure outer div's click event doesn't get triggered

      const imageSrc = webcamRef.current.getScreenshot();
      setSelectedAvatarName("Captured");
      setSelectedAvatar(imageSrc);
      setIsPictureTaken(true);
      setIsOpen(false);
    },
    [webcamRef]
  );

  const handleAvatarChange = (name) => {
    setIsPictureTaken(false);
    setIsOpen(false);
    avatars.map((avatar) => {
      if (avatar.name === name) {
        setSelectedAvatarName(avatar.name);
        setSelectedAvatar(avatar.image);
      }
    });
  };
  const handleSaveAvatar = () => {
    dispatch(
      setSelectedAvatarRedux({
        avatarName: selectedAvatarName,
        avatarSrc: selectedAvatar,
      })
    );
    navigate("/kyc/create-new-profile");
  };

  return (
    <>
      <div className="general-container change-avatar-container">
        <Title para={null} mainTitle={t("Change Avatar:")} />
        <div className="chosen-avatar">
          {/*  if isOpen=false  that means the camera is off we display avatar */}
          {!isOpen ? (
            <>
              <div className="avatar-wrapper">
                {selectedAvatar && (
                  <img
                    src={selectedAvatar}
                    alt="Captured"
                    className={
                      isPictureTaken || selectedAvatarName === "Captured"
                        ? "captured"
                        : "system-avatar"
                    }
                  />
                )}
              </div>

              <div
                className="take-photo-wrapper"
                onClick={() => setIsOpen(true)}
              >
                <div className="take-photo-icon">
                  <IconContext.Provider value={{ className: "upload-icon" }}>
                    <BsCameraFill style={{ fontSize: "20px" }} />
                  </IconContext.Provider>
                </div>
                <div className="take-photo-para">{t("Take a photo")}</div>
              </div>
            </>
          ) : (
            <>
              <div className="camera-container">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  imageSmoothing={true}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: "user",
                    width: "300px!important",
                    height: "300px!important",
                  }}
                  className="webcam-video"
                />
                <div
                  className="take-photo-wrapper capture"
                  onClick={(e) => capture(e)}
                >
                  <div className="take-photo-icon">
                    <IconContext.Provider value={{ className: "upload-icon" }}>
                      <BsCameraFill style={{ fontSize: "30px" }} />
                    </IconContext.Provider>
                  </div>
                  <div className="take-photo-para">{t("Capture")}</div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="list-of-avatars">
          {listOfAvatars.map((avatar) => {
            return (
              <div className="avatar" key={avatar.id}>
                <div className="avatar-container">
                  <div className="avatar-image">
                    <img
                      src={avatar.image}
                      alt=""
                      onClick={() => handleAvatarChange(avatar.name)}
                      className={
                        selectedAvatarName === avatar.name ? "active" : ""
                      }
                    />
                  </div>
                </div>
                <div className="avatar-name">{t(`${avatar.name}`)}</div>
              </div>
            );
          })}
        </div>
        <div
          className="button-wrapper"
          style={{ direction: languageDirection }}
          onClick={handleSaveAvatar}
        >
          <button>{t("Save")}</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeAvatar;
